import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import packageOffers from "../views/packageOffers.vue";
import Advertisement from "../views/Advertisement.vue";
import Billing from "../views/Billing.vue";
//import RTL from "../views/Rtl.vue";
import Notifications from "../views/Notifications.vue";
import Profile from "../views/Profile.vue";
import SignUp from "../views/SignUp.vue";
import category from "../views/category.vue";
import addmeal from "../views/addmeal.vue";
import addPackageOffer from "../views/addPackageOffer.vue";


const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Orders",
    component: Dashboard,
  },
  {
    path: "/tables",
    name: "Males",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/packageOffers",
    name: "packageOffers",
    component: packageOffers,
  },
  {
    path: "/Advertisement",
    name: "Advertisement",
    component: Advertisement,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/category",
    name: "category",
    component: category,
  },
  {
    path: "/addmeal",
    name: "addmeal",
    component: addmeal,
  },
  {
    path: "/addPackageOffer",
    name: "addPackageOffer",
    component: addPackageOffer,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
