<template>
  <div
    class="page-header align-items-start min-vh-100"
    :style="{backgroundImage: 'url(\'' + require('@/assets/img/resturent/addResturant.jpg') + '\')'}"
  >
    
    <div class="my-auto mx-auto col col-xl-4 col-lg-5 col-md-7 col-sm-10">
      <div class="row">
        <div class="mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom mx-auto">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-warning shadow-success border-radius-lg py-5 pe-1"
              >
              <div class='container-side-by-side'>
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0 textpadding">
                  SignIn
                </h4>
              </div>
               
              </div>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <material-input
                  id="email"
                  variant="static"
                  type="email"
                  label="Email"
                  placeholder="Email"
                  v-model="email"
                />
              </div>
              <div class="mb-3">
                <material-input
                  id="password"
                  variant="static"
                  type="password"
                  label="Password"
                  placeholder="Password"
                  v-model="password"
                />
              </div>
              <div class="text-center">
                <material-button
                  class="my-4 mb-2"
                  variant="gradient"
                  color="warning"
                  fullWidth
                  @click="loginFunction"
                >
                  Sign in
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { computed } from "vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

//import { useStore } from "vuex";

//import { ref } from "vue";
import { UserClass, save, BaseURL, saveLoginBool, imageURL, get_fcm } from './DATA/login';
import axios from "axios";

//import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
//const store = useStore();
export default {
  name: "sign-in",
  components: {
    MaterialInput,
    MaterialButton,
  },
  data() {
    return {
      email: "",
      password: "",
      loginbool: false,
      is_not_logging: false,
      errorMessege: "",
      login_image: imageURL+"dynamic%20Images/login%20background%20resturent.jpg"
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig" , "set_login", "set_notfoundAddress"]),

    async loginFunction() {
      try {
        const LogData = {
          email: this.email,
          password: this.password
        };
        
        this.is_not_logging = false;
        
        const response = await axios.post(`${BaseURL}login`, LogData);
        //console.log(response);
        if (response.status === 200) {
          const user = response.data.user[0];
          const userLog = new UserClass(
            response.data.token,
            user.blocked_until,
            user.cancel_count,
            user.created_at,
            user.email,
            user.email_verified_at,
            user.id,
            user.name,
            user.phone,
            user.status,
            user.updated_at
          );

          this.loginbool = true;
          save(userLog);
          saveLoginBool(this.loginbool);
          //console.log(store.login);
          this.set_login();
          //store.state.login= true;
          
          
          let config1 = {
            method: 'get',
            maxBodyLength: Infinity,
            url: BaseURL+'getUserAddresses',
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer '+ userLog.token
            }
          };

          axios.request(config1)
          .then((response) => {
            if(response.data.length < 1){
              this.set_notfoundAddress(true);
                  toast(`You Don't have Map Address yet`, {
                      autoClose: 2000,
                    });
            }
            else{
              this.set_notfoundAddress(false);
            }
              })
          .catch(() => {
          });
          let fcm = get_fcm();
          console.log("fcm in sign");
          console.log(fcm);
          const FormData = require('form-data');
          let data = new FormData();
          data.append('fcm_token', fcm);

          let config_fcm = {
            method: 'post',
            maxBodyLength: Infinity,
            url: BaseURL+'saveFcmToken',
            headers: { 
              'Authorization': 'Bearer '+userLog.token, 
            },
            data : data
          };

          axios.request(config_fcm)
          .then((response) => {
            console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });

        } else {
          this.is_not_logging = true;
          console.log("User is not logged");
        }
      } catch (err) {
        console.error(err);
        this.is_not_logging = true;
        this.errorMessege = `${err.response?.statusText || err.message}: Email or Password is incorrect`;
        toast(this.errorMessege, {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });
      }
    },
  },
};
</script>
<style scoped>
.page-header {
  background-size: cover;
  background-position: center;
  height: 100vh; /* يمكن تعديل الارتفاع حسب الحاجة */
}
.textpadding{
    padding-right: 0%;
    padding-left: 35%;
    position :fixed ;
  }
  .imgpadding{
    padding-right: 0%;
    padding-left: 5%;
  }
</style>