<template>
    <div v-if="visible"
      class="page-header align-items-start min-vh-100"
      :style="{backgroundImage: 'url(\'' + require('@/assets/img/resturent/mapImage.jpg') + '\')'}"
    >
      <span class="mask bg-gradient-warning opacity-2"></span>
      <div class="my-auto mx-auto col col-xl-4 col-lg-5 col-md-7 col-sm-10">
      <div class="row">
        <div class="mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom mx-auto">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-warning shadow-success border-radius-lg py-3 pe-1"
              >
              <div
                  class="bg-gradient-warning shadow-success border-radius-lg py-0 pe-1"
                >
                  <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                    Location
                  </h4>
                </div>
               
              </div>
            </div>
            <div class="card-body">
                <div class="mb-3">
                  <material-input
                    id="LcationName"
                    variant="static"
                    type="text"
                    label="LcationName"
                    placeholder="LcationName"
                    v-model="LcationName"
                  />
                </div>
                  your location : ( <code>{{ x }}</code> , <code>{{ y }}</code> )
                <div>
                </div>
                <div class="text-center">
                  <material-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="warning"
                    fullWidth
                    @click="sendLocation"
                  >
                  send Location
                  </material-button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script>
  //import { computed } from "vue";
  import MaterialInput from "@/components/MaterialInput.vue";
  import MaterialButton from "@/components/MaterialButton.vue";
  import { mapMutations } from "vuex";
  import 'vue3-toastify/dist/index.css';
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  //import { useStore } from "vuex";
  
  //import { ref } from "vue";
  import { userLog, BaseURL } from '../views/DATA/login';
  
  

  //const store = useStore();
  export default {
    name: "sendlocation",
    components: {
      MaterialInput,
      MaterialButton,
    },
    props: ['visible','x','y'],
    data() {
      return {
        LcationName: "",
        errorMessege: "",
      };
    },
    beforeMount() {
      toast("turn off VPN or any service maybe take fake-location", {
              theme: "colored",
              type: "warning",
              position: "top-right",
              autoClose: 10000,
            });
      this.toggleEveryDisplay();
      this.toggleHideConfig();
    },
    beforeUnmount() {
      this.toggleEveryDisplay();
      this.toggleHideConfig();
    },
    methods: {
      ...mapMutations(["toggleEveryDisplay", "toggleHideConfig" , "set_login", "set_notfoundAddress"]),
  
      async sendLocation() {
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        data.append('address_name', this.LcationName);
        data.append('x', this.x);
        data.append('y', this.y);

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: BaseURL+'addAddress',
          headers: { 
            'Authorization': 'Bearer '+userLog.token, 
          },
          data : data
        };

        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

      },
    },
  };
  </script>
  <style scoped>
  .page-header {
    background-size: cover;
    background-position: center;
    height: 100vh; /* يمكن تعديل الارتفاع حسب الحاجة */
  }
  </style>