import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";

import PrimeVue from "primevue/config";
import ToastService from 'primevue/toastservice';
import BootstrapVue3 from 'bootstrap-vue-3';


import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
const firebaseConfig = {
  apiKey: 'AIzaSyBwXHl16oTlQM4JETamUkPO6SJwA51uxUM',
  authDomain: 'deliveryapp-16e35.firebaseapp.com',
  projectId: 'deliveryapp-16e35',
  storageBucket: 'deliveryapp-16e35.appspot.com',
  messagingSenderId: '445752029618',
  appId: '1:445752029618:web:c764f00308cbf3e297b4ab',
  measurementId: 'G-JRJS412TKG',
}
firebase.initializeApp(firebaseConfig);
// Import the functions you need from the SDKs you need


const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(BootstrapVue3);
appInstance.use(PrimeVue, {
    unstyled: true
  });
appInstance.use(ToastService);
/*appInstance.use(Notify);
appInstance.use(GlobalComponents);*/


appInstance.mount("#app");

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch((error) => {
      console.log('Service Worker registration failed:', error);
    });
}
