<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card1 my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div class="custom-bg shadow-success border-radius-lg pt-4 pb-3 ">
              <div class="ms-auto">  
                <div class="d-flex flex-row justify-content-between">
                  <h6 class="text-warning text-capitalize ps-3 me-8">package Offers:</h6>
                  <div style="padding-right:5%">
                    <button class=" toRTL badge badge-lg bg-gradient-warning" @click="showpackageOffersadd = true;">
                      Add New Packege Offer
                      <i class="pi pi-plus-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0 table-container meal-table">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-white text-xxs font-weight-bolder opacity-7">
                      Name
                    </th>
                    <th class="text-uppercase text-white text-xxs font-weight-bolder opacity-7 ps-2">
                      discount
                    </th>
                    <th class="text-uppercase text-white text-xxs font-weight-bolder opacity-7 ps-2">
                      price after discount
                    </th>
                    <th class="text-center text-white text-secondary text-xxs font-weight-bolder opacity-7">
                      End Date offer
                    </th>
                    <th class="text-secondary opacity-7"></th>
                    <th class="text-secondary opacity-7"></th>
                    </tr>
                </thead>
                <tbody v-if="getpackageOffersBool">
                <template v-if="packageOfferscount > 0" >
                  <tr v-for="packageOffers in packageOfferscount" :key = packageOffers.id>
                    <td>
                      <div class="d-flex px-2 py-1 divcorsor" @click="showButtons(meal -1)">
                        <div>
                          <img :src=" packageOffersList_[packageOffers -1].URL" class="avatar avatar-sm me-3 border-radius-lg"
                            alt="Meal" />
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{packageOffersList_[packageOffers -1].name}}</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">{{packageOffersList_[packageOffers -1].discount}}</p>
                    </td>
                    <td>
                      <p class="text-xm font-weight-bold mb-0 price center"> {{packageOffersList_[packageOffers -1].price_after_discount}}</p>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">{{ packageOffersList_[packageOffers -1].end_date }}</span>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <button class="badge badge-sm bg-gradient-warning custom-button" @click="editpackageOffersFunction(packageOffersList_[packageOffers -1].id)">
                        Edit
                        <i class="pi pi-pencil"></i>
                      </button>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <button class="badge badge-sm bg-gradient-danger custom-button" @click="deletepackageOffersFunction(packageOffersList_[packageOffers -1])">
                        Delete
                        <i class="pi pi-trash"></i>
                      </button>
                    </td>
                  </tr>
                </template>
                </tbody>
              <div v-else>
                <div class="loader"></div>
              </div>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
   


    
    



  <div class="centered" v-if="showpackageOffersDelete">
      <div class="my-auto mx-auto col-5" >
      <div class="row">
        <div class="mx-auto">
          <div class="card z-index-0 fadeIn10 fadeInBottom mx-auto">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="custom-bg shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-warning font-weight-bolder text-center mt-2 mb-0">
                  Delete packageOffers
                </h4>
              </div>
            </div>
            <div class="card-body">
              <div>
                <p class="delete-text">Are you sure you want to delete {{ deletepackageOffers.name }} packageOffers?</p>
              </div>
            <div class="button-group">
              <b-button @click="deletepackageOffersAPI(deletepackageOffers.id)" variant="danger" class="bbutton">Delete</b-button>
              <b-button @click="showpackageOffersDelete = false" variant="warning" class="bbutton">Back</b-button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>




  <div class="centered" v-if="showpackageOffersadd">
      <div class="my-auto mx-auto col-5" >
      <div class="row">
        <div class="mx-auto">
          <div class="card z-index-0 fadeIn10 fadeInBottom mx-auto">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="custom-bg shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-warning font-weight-bolder text-center mt-2 mb-0">
                  ADD packageOffers
                </h4>
              </div>
            </div>
            <div class="card-body">
              <div>
                <p class="add-text text-warning">To Add This packageOffer, I ask you to go to the meals page, then select the meals, then press the button that appears at the top of the list <i class="pi pi-gift"></i></p>
                </div>
                <div class="button-group">
                  <router-link to="/tables">
                    <b-button type="submit" variant="warning">OK</b-button>
                  </router-link>
                  
                  <b-button @click="showpackageOffersadd = false" variant="danger">Back</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="centered" v-if="EditpackageOffersbool">
      <div class="my-auto mx-auto col-5" >
      <div class="row">
        <div class="mx-auto">
          <div class="card z-index-0 fadeIn10 fadeInBottom mx-auto">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="custom-bg shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-warning font-weight-bolder text-center mt-2 mb-0">
                  Edit packageOffers
                </h4>
              </div>
            </div>
            <div class="card-body">
              <div>
                <p class="add-text text-warning">To Edit This packageOffer, I ask you to go to the meals page, then select the meals, then press the button that appears at the top of the list <i class="pi pi-gift"></i></p>
              </div>
              <div class="button-group">
                <router-link to="/tables">
                  <b-button type="submit" variant="warning" @click="editpackageOffers">OK</b-button>
                </router-link>
                <b-button @click="EditpackageOffersbool = false" variant="danger">Back</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>






    </div>
  </div>
</template>

<script setup>





import 'vue-select/dist/vue-select.css';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { BaseURL, userLog ,imageURL,
  set_packageOfferToEditBool,set_id_packegeOffer_toEdit,
 } from './DATA/login';
import { ref } from 'vue';
import { useStore } from "vuex";


const store = useStore();

const axios = require('axios');


let resturantID = ref(0);
let packageOfferscount  = ref(0); 
let packageOffersList = [];
let packageOffersList_ = [];

let mealcount  = ref(0); 
let mealList = [];
let mealList_ = [];



let getpackageOffersBool = ref(false);


let showpackageOffersDelete=  ref(false);
let showpackageOffersadd = ref(false);
let EditpackageOffersbool = ref(false);





let deletepackageOffers= ref({
  id: '',
  name: '',
});



const deletepackageOffersFunction = (meal) =>{
  showpackageOffersDelete.value = true;
  deletepackageOffers.value.id = meal.id;
  deletepackageOffers.value.name = meal.name;
};

const deletepackageOffersAPI = async (mealID) =>{
  const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL+'deletePackage/'+mealID,
        headers: { 
          'Authorization': 'Bearer '+userLog.token, 
        },
      };
      try {
        const response = await axios.request(config);
        toast(response.data, {
              theme: "colored",
              type: "success",
              position: "top-left",
              autoClose: 3000,
            });
            packageOffersList_ = [];
            await getpackageOffers();
            showpackageOffersDelete.value = false;
      } catch (error) {
        toast(error, {
              theme: "colored",
              type: "error",
              position: "top-left",
              autoClose: 3000,
            });
      }
};





let IDPackegOffer = ref();
const editpackageOffersFunction = (id) => {
  EditpackageOffersbool.value = true;
  IDPackegOffer.value = id;
};
const editpackageOffers =  () => {
  store.state.offerPackageToEditID = IDPackegOffer.value;
  store.state.boolFoundIDs = true;
  EditpackageOffersbool.value = false;
  set_packageOfferToEditBool(true);
  set_id_packegeOffer_toEdit(IDPackegOffer.value);
};




  const getpackageOffers = async() => {
    let config_toc = {
        method: 'get',
        maxBodyLength: Infinity,
        url: BaseURL+'GetIdResturentByToken',
        headers: { 
          'Authorization': 'Bearer '+userLog.token
        }
      };
      await axios.request(config_toc)
      .then((response) => {
        resturantID.value = response.data
      })
      .catch(() => {
      });
    getpackageOffersBool.value = false;
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL+'allOfferPackage/'+ resturantID.value,
      headers: { 
        'Authorization': 'Bearer '+userLog.token
      }
    };
    await axios.request(config)
    .then((response) => {
      packageOffersList = response.data;
      for (let i = 0; i< packageOffersList.length; i++)
    {
      let one_packageOffers = { 
        id: packageOffersList[i].id,
        name: packageOffersList[i].name, 
        photo: packageOffersList[i].photo, 
        discount: packageOffersList[i].discount, 
        price_after_discount: packageOffersList[i].price_after_discount, 
        end_date: packageOffersList[i].end_date, 
        URL: imageURL + packageOffersList[i].photo,
      };

      packageOffersList_.push(one_packageOffers);
    }
    packageOfferscount.value = packageOffersList.length;
    getpackageOffersBool.value = true;
    })
    .catch(() => {
      toast("Error in get packageOffers..", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });
    });
  };

  const getmaels = async() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL+'MealByResturent',
      headers: { 
        'Authorization': 'Bearer '+userLog.token
      }
    };
    await axios.request(config)
    .then((response) => {
      mealList = response.data;
      for (let i = 0; i< mealList.length; i++)
    {
      let one_mael = { 
        id: mealList[i].id,
        name: mealList[i].name, 
        price: mealList[i].price, 
        URL: imageURL + mealList[i].photo,
      };
      mealList_.push(one_mael);
    }
        mealcount.value = mealList_.length;
    })
    .catch(() => {
      toast("Error in get Meals..", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });
    });
  };

  getmaels();
  getpackageOffers();

</script>


<style>


.contenerLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 500px;
}


.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid rgb(251, 99, 64);
  border-bottom: 4px solid rgb(251, 99, 64);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader2 {
  top: 50%;
  left: 50%;
  z-index: 1;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid rgb(251, 99, 64);
  border-bottom: 4px solid rgb(251, 99, 64);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.container {
  display: flex;
  justify-content: flex-end; /* يضع العنصر في أقصى اليسار */
  align-items: center;
  padding-left: 20rem; /* إضافة مسافة صغيرة على اليسار لضمان عدم تلاصقه مع الحافة */
}

.add-button {
  justify-content: flex-start; /* يضع العنصر في أقصى اليسار */

  background-color: transparent; /* يمكنك تغيير اللون كما تحب */
  border: none; /* إزالة الحدود الافتراضية للزر */
  color: white; /* لون الأيقونة */
  font-size: 2rem; /* حجم الأيقونة */
  cursor: pointer; /* تغيير المؤشر عند التحويم */
  display: flex;
  padding-left: 4rem; 
}

.add-button i {
  font-size: 2rem;
}
.file-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    background-color: #f9f9f9;
}


.imageInput {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: #f9f9f9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.imageInput:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.picture-input {
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.3s ease;
}

.picture-input:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.btn {
    background-color: #ffae00;
    color: #fff;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #0056b3;
}

.bmodal {
  background-color: #fff;
  padding: 20px;
  border: 2px solid rgb(5, 250, 238); /* يمكنك تغيير اللون والسماكة حسب الرغبة */
  border-radius: 10px; /* يمكنك تعديل الزوايا المدورة حسب الرغبة */
  padding: 1rem;
  margin-bottom: 1rem;
}

.bbutton {
  margin: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.bbutton:hover {
  transform: scale(1.05);
}

.bformgroup {
  margin-bottom: 20px;
}

.bforminput, .b-form-file,.bformtextarea {
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ffae00;
  transition: border-color 0.3s ease;
}

.bforminput:focus, .bformfile:focus,.bformtextarea:focus {
  border-color: #ffae00;
  box-shadow: 0 0 5px #ffae00;
}



.container-side-by-side { 
	display: flex; 
	flex-direction: row; 
	flex-wrap: wrap; 
} 

.card1 {
  width: 100%;
  max-width: 90%; /* زيادة الحد الأقصى للعرض */
  margin: 0 auto; /* لضمان أن البطاقة تكون في الوسط */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #f9f9f9;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card1:hover {
  transform: scale(1.01);
}


.cardadd1 {
  width: 40%;
  max-width: 40%; /* زيادة الحد الأقصى للعرض */
  margin: 0 auto; /* لضمان أن البطاقة تكون في الوسط */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #f9f9f9;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cardadd1:hover {
  transform: scale(1.01);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.description-text {
  max-width: 300px; /* يمكنك تعديل هذا العرض حسب الحاجة */
  word-wrap: break-word; /* لتقسيم الكلمات الكبيرة */
  overflow-wrap: break-word; /* لدعم المتصفحات الحديثة */
  white-space: normal; /* للسماح بالالتفاف على الأسطر المتعددة */
}

.modal-header {
  background-color: rgb(5, 250, 238);
  color: white;
}

.custom-button {
  border: none; /* إزالة الحواف */
  cursor: pointer; /* تغيير المؤشر عند التمرير على الزر */
  transition: transform 0.2s ease-in-out; /* إضافة حركة بسيطة */
}

.custom-button:hover {
  transform: scale(1.05); /* تكبير الزر قليلاً عند التمرير عليه */
}


.avatar-lg {
  width: 100%; /* تعيين العرض ليكون 100% */
  max-width: 300px; /* أقصى عرض للصورة */
  height: auto; /* تعيين الارتفاع ليكون تلقائياً */
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 15px;
}

.custom-avatar {
    width: 200px;
    height: 200px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.divcorsor{
  cursor: pointer;
}


.delete-text{
  color: #ff6e41;
  
  line-height: 1.8;
  font-family: "Times New Roman", Times, serif;
  font-style: oblique;
  font-weight: bold;
  font-size: 22px;
}

.add-text{
  color: rgb(0, 198, 109);
  
  line-height: 1.8;
  font-family: "Times New Roman", Times, serif;
  font-style: oblique;
  font-weight: bold;
  font-size: 22px;
}


.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* لضبط المسافة العلوية إذا لزم الأمر */
}

.button-group .bbutton {
  margin: 0 10px; /* لضبط المسافة الأفقية بين الأزرار */
}


.styled-date-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.styled-date-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-group .bbutton {
  margin: 0 10px;
}


#hiddenElement {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
}

.vertical-line {
    width: 2px; /* عرض الخط */
    height: 40%; /* طول الخط يساوي ارتفاع الشاشة بالكامل */
    background-color: rgb(158, 158, 158); /* لون الخط */
    position: absolute;
    left: 50%; /* موضع الخط في منتصف الصفحة */
    transform: translateX(-50%);
}


.textAddon .textAddonprice{
  color: #057014;
  display: inline-block;
  max-width: none;
}

.textAddonprice{
  color: #037514;
}

.textWithout{
color: #630d1d;
}

.table {
  width: auto;
}
th, td {
    white-space: nowrap;
}
.tdAddon{
  padding-right: 20%;
}

.tdAddon2{
  padding-left: 20%;
}
</style>