<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card1 my-4">
          <div class="card-header p-0 position-relative mt-n5 mx-3 z-index-2">
            <div class="custom-bg shadow-success border-radius-lg pt-4 pb-3">
              <div class="ms-auto">  
                <div class="d-flex flex-row justify-content-between">
                  <h6 class="text-warning text-capitalize ps-3 me-8">Category:</h6>
                  <div style="padding-right:5%">
                    <button class=" toRTL badge badge-lg bg-gradient-warning" @click="showCategoryModal = true">
                      Add New Category
                      <i class="pi pi-plus-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0 table-container  meal-table">
              <table class="table align-items-center justify-content-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-white text-xxs font-weight-bolder opacity-7">
                      <div  style="padding-left: 11%;">
                          Name
                      </div>
                    </th>
                    
                    <th></th>
                  </tr>
                </thead>
                <tbody v-if="getCategoryBool">
                  <tr v-for="category in categorycount" :key = category.id>
                    <td>
                      <div class="d-flex px-2">
                        <div style="padding-left: 10%;">
                          <img :src="categoryList[category-1].URL"
                            class="avatar avatar-sm rounded-circle me-2" alt="Category" />
                        </div>
                        <div class="my-auto">
                          <h6 class="mb-0 text-md text-warning">{{ categoryList[category-1].name }}</h6>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <button class="badge badge-sm bg-gradient-danger custom-button" @click="deleteCategoryFunction(categoryList[category-1])">
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
                <div v-else>
                <div class="loader"></div>
              </div>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="centered" v-if="showCategoryModal">
      <div class="my-auto mx-auto col-5" >
      <div class="row">
        <div class="mx-auto">
          <div class="card z-index-0 fadeIn10 fadeInBottom mx-auto">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="custom-bg shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-warning font-weight-bolder text-center mt-2 mb-0">
                  Add New Category
                </h4>
              </div>
            </div>
            <div class="card-body">
              <b-form-group label="Category Name" class="bformgroup">
                <b-form-input v-model="newCategory.name" required class="bforminput"></b-form-input>
              </b-form-group>
              <b-form-group label="Category Image" class="bformgroup">
                
              </b-form-group>
              <div class="imageInput">    
                <picture-input 
                  ref="pictureInput"
                  width="300" 
                  height="300" 
                  margin="50" 
                  accept="image/jpeg,image/png" 
                  size="10" 
                  button-class="btn"
                  :custom-strings="{
                    upload: '<h1>Bummer!</h1>',
                    drag: 'Drag an Image or Click to select Image from Files'
                  }"
                  @change="onCategoryFileChange">
                </picture-input>
              </div>
              <div class="button-group">
                <b-button @click="uploadCategory" variant="warning" class="bbutton">ADD Category</b-button>
                <b-button @click="showCategoryModal = false" variant="danger" class="bbutton">Cancel</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
    





  <div class="centered" v-if="showCategoryDelete">
      <div class="my-auto mx-auto col-4" >
      <div class="row">
        <div class="mx-auto">
          <div class="card z-index-0 fadeIn10 fadeInBottom mx-auto">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="custom-bg shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-warning font-weight-bolder text-center mt-2 mb-0">
                  Delete Category
                </h4>
              </div>
            </div>
            <div class="card-body">
              <div>
                <p class="delete-text">Are you sure you want to delete {{ deleteCategory.name }} Category?</p>
                <p class="delete-detail">Meals falling under this category will not be deleted {{ deleteCategory.name }} Category?</p>
              </div>
            <div class="button-group">
              <b-button @click="deleteCategoryAPI(deleteMeal.id)" variant="warning" class="bbutton">Delete Category</b-button>
              <b-button @click="showCategoryDelete = false" variant="danger" class="bbutton">Cancel</b-button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  </div>
</template>

<script setup>

import 'vue-select/dist/vue-select.css';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { BaseURL, userLog ,imageURL } from './DATA/login';
import { ref /*, onUnmounted */} from 'vue';
import PictureInput from 'vue-picture-input'
//import { BModal, BForm, BFormGroup, BFormInput, BFormFile, BButton } from 'bootstrap-vue-next'


/*import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();*/


const axios = require('axios');


let categorycount  = ref(0); 
let categoryList = [];
let categoryList_ = [];
let getCategoryBool = ref(false);
let showCategoryModal=  ref(false);
let showCategoryDelete=  ref(false);

let newCategory =  ref({
  name: '',
  image: null
});
let deleteCategory= ref({
  id: '',
  name: '',
});


const deleteCategoryFunction = (Category) =>{
  showCategoryDelete.value = true;
  deleteCategory.value.id = Category.id;
  deleteCategory.value.name = Category.name;
};

const deleteCategoryAPI = async (CategoryID) =>{
  const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL+'delete/'+CategoryID,
        headers: { 
          'Authorization': 'Bearer '+userLog.token, 
        },
      };

      try {
        const response = await axios.request(config);
        toast(response.data.Result, {
              theme: "colored",
              type: "success",
              position: "top-left",
              autoClose: 3000,
            });
            showCategoryDelete.value = false;
      } catch (error) {
        toast("Errorr when Delete Category", {
              theme: "colored",
              type: "error",
              position: "top-left",
              autoClose: 3000,
            });
      }
};






function onCategoryFileChange(event) {
      newCategory.value.image = event.target.files[0];
    };



const uploadCategory = async() => {
      const formData = new FormData();
      formData.append('name', this.newCategory.name);
      formData.append('image', this.newCategory.image);
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL+'createCategory',
        headers: { 
          'Authorization': 'Bearer '+userLog.token, 
        },
        data: formData
  };
      try {
        const response = await axios.request(config);
        toast(response.data.message, {
          theme: "colored",
          type: "success",
          position: "top-left",
          autoClose: 3000,
        });
        
        getCategory();
        showCategoryModal.value = false;
      } catch (error) {
        toast("Erroe when Add category", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });
      }
    };
    
    





  const getCategory = async() => {
    getCategoryBool.value = false;
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL+'getCategoryMyOwner',
      headers: { 
        'Authorization': 'Bearer '+userLog.token
      }
    };
    await axios.request(config)
    .then((response) => {
      categoryList_ = response.data;
      for (let i = 0; i< categoryList_.length; i++)
    {
      let one_category = { 
        id: categoryList_[i].id,
        name: categoryList_[i].name, 
        photo: categoryList_[i].photo, 
        URL: imageURL + categoryList_[i].photo,
      };
      categoryList.push(one_category);
    }
    categorycount.value = categoryList_.length;
    getCategoryBool.value = true;
    })
    .catch(() => {
      toast("Error in get Category..", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });
    });
  };

  getCategory();

</script>


<style>


.contenerLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 500px;
}


.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid rgb(251, 99, 64);
  border-bottom: 4px solid rgb(251, 99, 64);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader2 {
  top: 50%;
  left: 50%;
  z-index: 1;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid rgb(251, 99, 64);
  border-bottom: 4px solid rgb(251, 99, 64);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.container {
  display: flex;
  justify-content: flex-end; /* يضع العنصر في أقصى اليسار */
  align-items: center;
  padding-left: 20rem; /* إضافة مسافة صغيرة على اليسار لضمان عدم تلاصقه مع الحافة */
}

.add-button {
  justify-content: flex-start; /* يضع العنصر في أقصى اليسار */

  background-color: transparent; /* يمكنك تغيير اللون كما تحب */
  border: none; /* إزالة الحدود الافتراضية للزر */
  color: white; /* لون الأيقونة */
  font-size: 2rem; /* حجم الأيقونة */
  cursor: pointer; /* تغيير المؤشر عند التحويم */
  display: flex;
  padding-left: 4rem; 
}


.add-button i {
  font-size: 2rem;
}
.file-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    background-color: #f9f9f9;
}


.imageInput {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: #f9f9f9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.imageInput:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.picture-input {
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.3s ease;
}

.picture-input:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.btn {
    background-color: #ffae00;
    color: #fff;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #0056b3;
}

.bmodal {
  background-color: #fff;
  padding: 20px;
  border: 2px solid rgb(5, 250, 238); /* يمكنك تغيير اللون والسماكة حسب الرغبة */
  border-radius: 10px; /* يمكنك تعديل الزوايا المدورة حسب الرغبة */
  padding: 1rem;
  margin-bottom: 1rem;
}

.bbutton {
  margin: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.bbutton:hover {
  transform: scale(1.05);
}

.bformgroup {
  margin-bottom: 20px;
}

.bforminput, .b-form-file,.bformtextarea {
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ffae00;
  transition: border-color 0.3s ease;
}

.bforminput:focus, .bformfile:focus,.bformtextarea:focus {
  border-color: #ffae00;
  box-shadow: 0 0 5px #ffae00;
}



.container-side-by-side { 
	display: flex; 
	flex-direction: row; 
	flex-wrap: wrap; 
} 

.card1 {
  width: 100%;
  max-width: 90%; /* زيادة الحد الأقصى للعرض */
  margin: 0 auto; /* لضمان أن البطاقة تكون في الوسط */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #f9f9f9;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card1:hover {
  transform: scale(1.01);
}


.cardadd1 {
  width: 40%;
  max-width: 40%; /* زيادة الحد الأقصى للعرض */
  margin: 0 auto; /* لضمان أن البطاقة تكون في الوسط */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #f9f9f9;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cardadd1:hover {
  transform: scale(1.01);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.description-text {
  max-width: 300px; /* يمكنك تعديل هذا العرض حسب الحاجة */
  word-wrap: break-word; /* لتقسيم الكلمات الكبيرة */
  overflow-wrap: break-word; /* لدعم المتصفحات الحديثة */
  white-space: normal; /* للسماح بالالتفاف على الأسطر المتعددة */
}

.modal-header {
  background-color: rgb(5, 250, 238);
  color: white;
}

.custom-button {
  border: none; /* إزالة الحواف */
  cursor: pointer; /* تغيير المؤشر عند التمرير على الزر */
  transition: transform 0.2s ease-in-out; /* إضافة حركة بسيطة */
}

.custom-button:hover {
  transform: scale(1.05); /* تكبير الزر قليلاً عند التمرير عليه */
}


.avatar-lg {
  width: 100%; /* تعيين العرض ليكون 100% */
  max-width: 300px; /* أقصى عرض للصورة */
  height: auto; /* تعيين الارتفاع ليكون تلقائياً */
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 15px;
}

.custom-avatar {
    width: 200px;
    height: 200px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.category-item {
    margin-bottom: 15px; /* يمكنك تعديل القيمة كما تريد */
    cursor: pointer;
}
.meal-item_packege {
    margin-bottom: 20px; /* يمكنك تعديل القيمة كما تريد */
}

.divcorsor{
  cursor: pointer;
}


.delete-text{
  color: #ff6e41;
  
  line-height: 1.8;
  font-family: "Times New Roman", Times, serif;
  font-style: oblique;
  font-weight: bold;
  font-size: 22px;
}


.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* لضبط المسافة العلوية إذا لزم الأمر */
}

.button-group .bbutton {
  margin: 0 10px; /* لضبط المسافة الأفقية بين الأزرار */
}


.styled-date-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.styled-date-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-group .bbutton {
  margin: 0 10px;
}


#hiddenElement {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
}

.vertical-line {
    width: 2px; /* عرض الخط */
    height: 40%; /* طول الخط يساوي ارتفاع الشاشة بالكامل */
    background-color: rgb(158, 158, 158); /* لون الخط */
    position: absolute;
    left: 50%; /* موضع الخط في منتصف الصفحة */
    transform: translateX(-50%);
}


.textAddon .textAddonprice{
  color: #057014;
  display: inline-block;
  max-width: none;
}

.textAddonprice{
  color: #037514;
}

.textWithout{
color: #630d1d;
}

.table {
  width: auto;
}
th, td {
    white-space: nowrap;
}
.tdAddon{
  padding-right: 20%;
}

.tdAddon2{
  padding-left: 20%;
}

@keyframes shakeAndGrow {
      0%, 100% {
        transform: translateX(0) scale(1);
      }
      10%, 30%, 50%, 70%, 90% {
        transform: translateX(-10px) scale(1.1);
      }
      20%, 40%, 60%, 80% {
        transform: translateX(10px) scale(0.9);
      }
    }
    


</style>