<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card1 my-4">
          <div class="card-header p-0 position-relative mt-n5 mx-3 z-index-2">
            <div class="custom-bg shadow-success border-radius-lg pt-4 pb-3 ">
              <div class="ms-auto">  
                <div class="d-flex flex-row justify-content-between">
                  <i class="divcorsor pi pi-angle-left" v-if="showAddonswithoutsToMeal == true" @click="showAddonswithoutsToMeal = false"
                  style="color: coral; font-size: 2rem; padding-left:2%"></i>
                  <h6 class="text-warning text-capitalize ps-3 me-8" v-if="showAddonswithoutsToMeal == false">Males:</h6>
                  <h5 class="text-warning font-weight-bold text-capitalize ps-3 me-8" v-else>{{ mealDetailsToShowInAddonsAndWithout.name }}</h5>
                    <div class="packageDiv" v-if="showButtonAddPackegeOffers == true  && showAddonswithoutsToMeal == false">
                      <button class=" toRTL badge badge-lg bg-gradient-warning" @click="showAddPackegeOffersModal = true">
                        Add Package Offer
                        <i class="pi pi-gift"></i>
                      </button>
                    </div>
                    <div class="editDiv" v-else>
                      <button class=" toRTL badge badge-lg bg-gradient-warning" @click="editMealFunction(mealDetailsToShowInAddonsAndWithout)" v-if="showAddonswithoutsToMeal == true">
                        Edit meal
                        <i class="pi pi-pencil"></i>
                      </button>
                    </div>
                    <div class="addDiv">
                      <button class=" toRTL badge badge-lg bg-gradient-warning" @click="goAddMealOrEdit()" v-if="showAddonswithoutsToMeal == false">
                        Add New Meal
                        <i class="pi pi-plus-circle"></i>
                      </button>
                      <button class=" toRTL badge badge-lg bg-gradient-warning" @click="deleteMealFunction(mealDetailsToShowInAddonsAndWithout)" v-if="showAddonswithoutsToMeal == true">
                        Delete meal
                        <i class="pi pi-trash"></i>
                      </button>
                    </div>    
                </div>
              </div>
            </div>

          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0 table-container">


              <div v-if="showAddonswithoutsToMeal == true">

                <div class="container-side-by-side">
                  <div class="col-3">
                    <div class="align-middle text-center" style="padding-top:5%;padding-bottom: 10%;">
                      <p class=" text-lg text-dark-emphasis font-weight-bold mb-0">{{ mealDetailsToShowInAddonsAndWithout.categoryName }}</p>
                    </div>
                    <div class="container-side-by-side">
                      <div class="col-6 imgdetailpaddig">
                        <img :src="mealDetailsToShowInAddonsAndWithout.URL" class="avatar avatar-xxl me-3 border-radius-lg"
                          alt="Meal" />
                      </div>
                      <div class="col-6">
                        <div v-if="!(mealDetailsToShowInAddonsAndWithout.end_date === null)">
                          <p class="text-center text-secondary text-xs font-weight-bold">{{mealDetailsToShowInAddonsAndWithout.end_date}}</p>
                          <h3 class="text-center text-warning font-weight-bold mb-0">{{mealDetailsToShowInAddonsAndWithout.price_after_discount}}</h3>
                          <p class="text-center text-sm font-weight-bold mb-0">{{mealDetailsToShowInAddonsAndWithout.price}}</p>
                        </div>
                        <div v-else>
                          <h3 class="text-warning font-weight-bold mb-0"> {{mealDetailsToShowInAddonsAndWithout.price}}</h3>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p class=" text-right text-lg text-danger-emphasis font-weight-bold mb-0 description-text">
                        {{mealDetailsToShowInAddonsAndWithout.description}}
                      </p>
                    </div>
                  </div>


                  <div  class="col-3" v-if="!showAddAddonsToMeal">
                <div  v-if="Addonscount > 0">
                <div class=" section-container">
                  <div v-if="getAddonsBool">
                      <table class="table align-items-center mb-0 meal-table">
                        <thead>
                          <tr>
                            <th class="text-center text-uppercase text-white text-xxs font-weight-bolder opacity-12">
                              addon
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="Addon in AddonsList" :key = Addon.id>
                            <td class="tdAddon">
                              <div class= "justify-content-center">
                                <h6 class="text-center mb-0 text-sm textAddon">{{Addon.addon}}</h6>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                  </div>
                  <div v-else>
                    <div class="loader"></div>
                  </div>
                </div>
                <div style="padding-top: 10%;padding-right: 18%;padding-bottom: 20%;">
                  <button class="toRTL badge badge-lg bg-gradient-warning" @click="showAddAddonsFunction">
                    Add New Addon
                    <i class="pi pi-plus-circle" style="color: azure;"></i>
                  </button>
                </div>
                <div class="" style="padding-top:5%"></div>
              </div>

                <div class="section-container" v-else>
                  <h5 class=" text-center text-warning noAddonText">No Addon to this Meal..</h5>
                  <div style="padding-bottom: 10%; padding-right: 5%;">
                    <button class=" toRTL badge badge-lg bg-gradient-warning" @click="showAddAddonsFunction">
                      Add New Addon
                      <i class="pi pi-plus-circle" style="color: azure;"></i>
                    </button>
                  </div>
                  <div class="" style="padding-top:5%"></div>
                </div>
              </div>
              <div v-else class="col-3">
                <div class=" section-container" style="padding-left:10%;padding-right:10%;padding-top:10%;padding-bottom:10%">
                      <b-form-group label="Input Addon please:" class="bformgroup">
                        <b-form-input v-model="AddAddons.addon" class="bforminput" required></b-form-input>
                      </b-form-group>
                    <div class="button-group">
                      <button @click="AddAddonsToMealAPI(AddAddons.id)" class="badge badge-sm bg-gradient-warning custom-button">ADD</button>
                      <button @click="showAddAddonsToMeal = false" class="badge badge-sm bg-gradient-danger custom-button">back</button>
                    </div>
                    </div>
              </div>




              <div  class="col-3" v-if="!showAddwithoutToMeal">
                <div v-if="Withoutcount > 0">
                  <div class="section-container">
                    <div v-if="getWithoutBool">
                      <table class="table align-items-center mb-0 meal-table">
                        <thead>
                          <tr>
                            <th class="text-center text-uppercase text-white text-xxs font-weight-bolder opacity-12">
                              Without
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="Without in WithoutList" :key = Without.id>
                            <td class="tdAddon">
                              <div class= "justify-content-center">
                                <h6 class="text-center mb-0 text-sm textWithout">{{Without.without_name}}</h6>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div v-else>
                      <div class="loader"></div>
                    </div>
                  </div>
                
                <div style="padding-top: 10%;padding-right: 18%; padding-bottom: 20%;">
                    <button class="toRTL badge badge-lg bg-gradient-warning" @click="showAddwithoutFunction">
                      Add New Without
                      <i class="pi pi-plus-circle" style="color: azure;"></i>
                    </button>
                  </div>
                  <div class="" style="padding-top:5%"></div>
                </div>
                
                <div class="section-container" v-else>
                  <h5 class=" text-center text-warning noAddonText">No  Without to this Meal..</h5>
                  <div style="padding-bottom: 10%; padding-right: 5%;">
                    <button class=" toRTL badge badge-lg bg-gradient-warning" @click="showAddwithoutFunction">
                      Add New Without
                      <i class="pi pi-plus-circle" style="color: azure;"></i>
                    </button>
                  </div>
                  <div class="" style="padding-top:5%"></div>
                </div>
              </div>

              <div v-else class="col-3">
                  <div class=" section-container" style="padding-left:10%;padding-right:10%;padding-top:10%;padding-bottom:10%">
                    <b-form-group label="Input Without please:" class="bformgroup">
                      <b-form-input v-model="Addwithout.without_name" class="bforminput" required></b-form-input>
                    </b-form-group>
                    <div class="button-group">
                      <button @click="AddwithoutToMealAPI(Addwithout.id)" class="badge badge-sm bg-gradient-warning custom-button">ADD</button>
                      <button @click="showAddwithoutToMeal = false" class="badge badge-sm bg-gradient-danger custom-button">back</button>
                    </div>
                  </div>
                </div>

                
                <div class="col-3 align-middle text-center text-lg">
                  <div class="" v-if="showOfferToMeal">
                    <h6 class="text-warning font-weight-bolder text-center mt-2 mb-0" v-if="!showOfferEdit">
                      Add Offer To Meal
                    </h6>
                    <h6 class="text-warning font-weight-bolder text-center mt-2 mb-0" v-else>
                      Edit Offer in Meal
                    </h6>

                    <b-form-group label="Offer Date" label-for="meal-date">
                      <input type="Date" id="OfferToMeal" name="bdaymonth" v-model="OfferToMeal.date" :min="minDate" class="styled-date-input" placeholder="Choose a date">
                    </b-form-group>
                    <b-form-group label="Meal Price After Discount" class="bformgroup">
                        <b-form-input v-model="OfferToMeal.price" class="bforminput" required type="number"></b-form-input>
                    </b-form-group>
                    <div class="button-group">
                      <button @click="OfferToMealAPI(OfferToMeal.id)" class="badge badge-sm bg-gradient-warning custom-button" v-if="!showOfferEdit">ADD Offer</button>
                      <button @click="OfferToMealAPI(OfferToMeal.id)" class="badge badge-sm bg-gradient-warning custom-button" v-else>Edit Offer</button>
                      <button @click="showOfferToMeal = false" class="badge badge-sm bg-gradient-danger custom-button">Back</button>
                    </div>
                </div>
                  <div v-else>
                    <div v-if="mealDetailsToShowInAddonsAndWithout.OfferAdded">
                      <button class="badge badge-sm bg-gradient-warning text-lg custom-button" @click="showEditOfferFunction(mealDetailsToShowInAddonsAndWithout)">
                        Edit Offer
                        <i class="pi pi-pencil" style="color: azure;"></i>
                      </button>
                      <button class="badge badge-sm bg-gradient-danger text-lg custom-button" @click="showDeleteOfferFunction(mealDetailsToShowInAddonsAndWithout.id)">
                        Delete Offer
                        <i class="pi pi-trash" style="color: azure;"></i>
                      </button>
                    </div>
                    <button v-else class="badge badge-sm bg-gradient-warning text-lg custom-button" @click="showAddOfferFunction(mealDetailsToShowInAddonsAndWithout)">
                      Add Offer
                      <i class="pi pi-plus-circle font-size: 2rem" style="color: azure;"></i>
                    </button>
                  </div>
                </div>
                </div>
              </div>
              <div v-else>
                <table class="table align-items-center mb-0 meal-table" v-if="mealcount > 0">
                  <thead>
                    <tr>
                      <th class="text-secondary opacity-7"></th>
                      <th class="text-uppercase text-white text-xxs font-weight-bolder opacity-12">
                        Name & Description
                      </th>
                      <th class="text-uppercase text-white text-xxs font-weight-bolder opacity-12 ps-2">
                        Price
                      </th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder opacity-12">
                        Category
                      </th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder opacity-12">
                        End Date offer
                      </th>
                      <th class="text-secondary opacity-7"></th>
                      <th class="text-secondary opacity-7"></th>
                      <th class="text-secondary opacity-7"></th>
                      </tr>
                  </thead>
                  <tbody v-if="getMealBool">
    
                  
                    <tr v-for="meal in mealcount" :key = meal.id>
                      <td>
                        <div v-if="!mealList_[meal -1].selected" class="add-to_package center" @click="toggleMealSelection(mealList_[meal -1].id,mealList_[meal -1].name, mealList_[meal -1].URL)"><i class="pi pi-plus-circle"></i></div>
                        <div v-else class="add-to_package2 center" @click="toggleMealSelection(mealList_[meal -1].id,mealList_[meal -1].name, mealList_[meal -1].URL)"><i class="pi pi-minus-circle"></i></div>
                      </td>
                      <td>
                        <div :class="{ 'selected-row': selectedMeals.includes(mealList_[meal - 1].id) }" class="d-flex px-2 py-1 divcorsor" @click="showDetialMeal(meal -1)">
                          <div>
                            <img :src=" mealList_[meal -1].URL" class="avatar avatar-lg-12 me-3 border-radius-lg-12"
                              alt="Meal" />
                          </div>
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-lg text-warning">{{mealList_[meal -1].name}}</h6>
                            <p class="text-m text-warning-emphasis mb-0 description-text text-warning-emphasis">
                              {{mealList_[meal -1].description}}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td v-if="!(mealList_[meal -1].end_date === null)">
                        <p class="text-lg font-weight-bold mb-0 text-warning">{{mealList_[meal -1].price_after_discount}}</p>
                        <p class="text-xs font-weight-bold mb-0">{{mealList_[meal -1].price}}</p>
                      </td>
                      <td v-else>
                        <p class="text-lg font-weight-bold mb-0 text-warning"> {{mealList_[meal -1].price}}</p>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <p class="text-xs font-weight-bold mb-0">{{ mealList_[meal -1].categoryName }}</p>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"  v-if="!(mealList_[meal -1].end_date == null)">{{ mealList_[meal -1].end_date }}</span>
                        <span class="text-secondary text-xs font-weight-bold"  v-else>--</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <button class="badge badge-sm bg-gradient-warning custom-button" @click="editMealFunction(mealList_[meal -1])">
                          Edit
                          <i class="pi pi-pencil"></i>
                        </button>
                      </td>
                      <td class="align-middle text-center text-sm">
                        
                        <button v-if="mealList_[meal -1].OfferAdded" class="badge badge-sm bg-gradient-info custom-button" @click="showDetialMeal(meal -1)">
                          info
                          <i class="pi pi-info-circle"></i>
                        </button>

                        <button v-else class="badge badge-sm bg-gradient-warning custom-button" @click="showAddOfferFunction(mealList_[meal -1])">
                          Add to Offer
                        </button>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <button class="badge badge-sm bg-gradient-danger custom-button" @click="deleteMealFunction(mealList_[meal -1])">
                          Delete
                          <i class="pi pi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  
                  </tbody>
                <div v-else>
                  <div class="loader"></div>
                </div>
                </table>
                <div v-else>
                    <img src="@/assets/img/resturent/no meal 1.jpg" class="center" style="width:100%;height:100%;" alt="Meal" />
                  </div>

              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      






<div class="centered" v-if="showMealDelete">
      <div class="my-auto mx-auto col-5" >
      <div class="row">
        <div class="mx-auto">
          <div class="card z-index-0 fadeIn10 fadeInBottom mx-auto">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="custom-bg shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-warning font-weight-bolder text-center mt-2 mb-0">
                  Delete Meal
                </h4>
              </div>
            </div>
            <div class="card-body">
              <div>
                <p class="delete-text">Are you sure you want to delete {{ deleteMeal.name }} meal?</p>
              </div>
            <div class="button-group">
              <b-button @click="deleteMealAPI(deleteMeal.id)" variant="danger" class="bbutton">Delete</b-button>
              <b-button @click="showMealDelete = false" variant="warning" class="bbutton">Back</b-button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  










  <div class="centered" v-if="showOfferDelete">
      <div class="my-auto mx-auto col-4" >
      <div class="row">
        <div class="mx-auto">
          <div class="card z-index-0 fadeIn20 fadeInBottom mx-auto">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="custom-bg shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-warning font-weight-bolder text-center mt-2 mb-0">
                  Delete Offer
                </h4>
              </div>
            </div>
            <div class="card-body">
              <div>
                <p class="delete-text">Are you sure you want to Delete Offer?</p>
              </div>  
            <div class="button-group">
              <b-button @click="deleteOfferAPI(OfferToDelete.id)" variant="danger" class="bbutton">Delete</b-button>
              <b-button @click="showOfferDelete = false" variant="warning" class="bbutton">Back</b-button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>












  <div class="centered" v-if="showAddPackegeOffersModal">
      <div class="my-auto mx-auto col-lg-3 col-md-7 col-sm-11" >
      <div class="row">
        <div class="mx-auto">
          <div class="card z-index-0 fadeIn10 fadeInBottom mx-auto">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="custom-bg shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-warning font-weight-bolder text-center mt-2 mb-0">
                  {{boolFoundIDs? 'Edit packageOffer' : 'Add New packageOffer'}}
                </h4>
              </div>
            </div>
            <div class="card-body">
              <div class="button-group">
                <div class="col-12">
                  <b-form-group label="Selected meals, continue to the next step." class="bformgroup">
                    <div v-for="meal in selectedMealsData" :key = meal.id class="meal-item_packege package-container">
                    <img :src="meal.URL" class="avatar avatar-md me-3 border-radius-lg"  
                      width="30" height="30"> {{meal.name}}
                  </div>
                  </b-form-group>
                  
                </div>
                
                </div>
            <div class="button-group">
              <b-button @click="uploadpackageOffers" variant="warning" class="bbutton">ADD Offer</b-button>
              <b-button @click="showAddPackegeOffersModal = false" variant="danger" class="bbutton">Back</b-button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



    </div>
  </div>
</template>

<script setup>

import 'vue-select/dist/vue-select.css';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { BaseURL, userLog ,imageURL, saveMeal, set_mealDataToEditBool,
  set_mealsID, set_selectedMeals, set_selectedMealsData,
  get_id_packegeOffer_toEdit,get_packageOfferToEditBool} from './DATA/login';
import { ref  } from 'vue';


import { computed } from "vue";
//import { useStore } from "vuex";
import { useRouter } from 'vue-router';
const router = useRouter()

function goAddMealOrEdit() { 
  router.push({ path: '/addmeal' })
}



//const store = useStore();


const axios = require('axios');

//const nomeal = "@/assets/img/resturent/addResturant.jpg";

let mealcount  = ref(1); 
let Addonscount  = ref(-1); 
let Withoutcount  = ref(-1); 



let categorycount  = ref(0); 
let categoryList = ref([]);
let categoryList_ = [];
let getCategoryBool = ref(false);


let mealList = [];
let mealList_ = [];


let mealsID = ref([])
let selectedMeals = ref([])
let selectedMealsData = ref([]);

let AddonsList = [];
let AddonsList_ = [];
let WithoutList = [];
let WithoutList_ = [];

let getMealBool = ref(false);
let getAddonsBool = ref(false);
let getWithoutBool = ref(false);

let showMealDelete=  ref(false);
let showOfferToMeal=  ref(false);
let showOfferDelete=  ref(false);
let showOfferEdit=  ref(false);
let showAddAddonsToMeal=  ref(false);
let showAddwithoutToMeal=  ref(false);
let showAddonswithoutsToMeal=  ref(false);
let showButtonAddPackegeOffers=  ref(false);
let showAddPackegeOffersModal=  ref(false);

let minDate = new Date().toISOString().split('T')[0]





const uploadpackageOffers = async () => {
  //savenewpackageOffers(newpackageOffers.value.name, newpackageOffers.value.meal_ids, newpackageOffers.value.discount, newpackageOffers.value.end_date, newpackageOffers.value.photo)
  router.push({ path: '/addPackageOffer' })
};






let deleteMeal= ref({
  id: '',
  name: '',
});




let OfferToMeal= ref({
  id: '',
  price: null,
  date: null
});

let OfferToDelete= ref({
  id: '',
  price: null,
  date: null
});




let AddAddons= ref({
  id: '',
  addon: '',
});

let Addwithout= ref({
  id: '',
  without_name: '',
});


let mealDetailsToShowInAddonsAndWithout= ref({
  id: '',
  name: '', 
  price: null, 
  description:'', 
  end_date: '', 
  price_after_discount: null, 
  categoryName: '', 
  URL: null,
});






const toggleMealSelection = (id, name, URL) => {
  let one_mael = { 
        id: id,
        name: name, 
        URL: URL,
  }
  let ind = ref(0);
  for (let i = 0; i <mealList_.length; i++) {
      if (mealList_[i].id == id)
        ind.value = i;
  };
  const index = mealsID.value.findIndex(meal => meal.mealId === id);
  const index2 = selectedMeals.value.findIndex(meal => meal === id);
  const index3 = selectedMealsData.value.findIndex(meal => meal.id === id);
  
  if (index === -1) {
    mealsID.value.push({ mealId: id });
    mealList_[ind.value].selected = true;
  } else {
    mealsID.value.splice(index, 1);
    mealList_[ind.value].selected = false;
  }

  if (index2 === -1) {
    selectedMeals.value.push(id);
  } else {
    selectedMeals.value.splice(index, 1);
  }

  if (index3 === -1) {
    selectedMealsData.value.push(one_mael);
  } else {
    selectedMealsData.value.splice(index3, 1);
  }

  if(mealsID.value.length > 0){
    //set_packageOfferToEditBool(true);
    set_mealsID(mealsID.value);
    set_selectedMeals(selectedMeals.value);
    set_selectedMealsData(selectedMealsData.value);
    showButtonAddPackegeOffers.value = true;

  }else {
    //set_packageOfferToEditBool(false);
    set_mealsID([]);
    set_selectedMeals([]);
    set_selectedMealsData([]);
    showButtonAddPackegeOffers.value = false;
  }
  };
const showDetialMeal = async (meal) => {
  showAddonswithoutsToMeal.value = true;

  mealDetailsToShowInAddonsAndWithout.value.id = mealList_[meal].id
  mealDetailsToShowInAddonsAndWithout.value.name = mealList_[meal].name
  mealDetailsToShowInAddonsAndWithout.value.description = mealList_[meal].description
  mealDetailsToShowInAddonsAndWithout.value.end_date = mealList_[meal].end_date
  mealDetailsToShowInAddonsAndWithout.value.price_after_discount = mealList_[meal].price_after_discount
  mealDetailsToShowInAddonsAndWithout.value.categoryName = mealList_[meal].categoryName
  mealDetailsToShowInAddonsAndWithout.value.URL = mealList_[meal].URL
  mealDetailsToShowInAddonsAndWithout.value.price = mealList_[meal].price
  mealDetailsToShowInAddonsAndWithout.value.OfferAdded = mealList_[meal].OfferAdded;

  await getWithouts(mealDetailsToShowInAddonsAndWithout.value.id);
  await getAddons(mealDetailsToShowInAddonsAndWithout.value.id);
  
}

const showAddwithoutFunction = async () => {
  //showAddonswithoutsToMeal.value = false;
  showAddwithoutToMeal.value = true;
  Addwithout.value.id = mealDetailsToShowInAddonsAndWithout.value.id;
};

const AddwithoutToMealAPI = async (mealID) => {
  const formData = new FormData();
      formData.append('without_name', Addwithout.value.without_name);
  const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL+'addNewWitoutToMeal/'+mealID,
        headers: { 
          'Authorization': 'Bearer '+userLog.token, 
        },
        data: formData
      };

      try {
        const response = await axios.request(config);
        toast(response.data.message, {
              theme: "colored",
              type: "success",
              position: "top-left",
              autoClose: 3000,
            });
            showAddwithoutToMeal.value = false;
            showAddonswithoutsToMeal.value = true;
            getWithouts(Addwithout.value.id);

      } catch (error) {
        toast(error.message, {
              theme: "colored",
              type: "error",
              position: "top-left",
              autoClose: 3000,
            });
      }
};


const showAddAddonsFunction = async () => {
  //showAddonswithoutsToMeal.value = false;
  showAddAddonsToMeal.value = true;
  AddAddons.value.id = mealDetailsToShowInAddonsAndWithout.value.id;
};


const AddAddonsToMealAPI = async (mealID) => {
  const formData = new FormData();
      formData.append('addon', AddAddons.value.addon);
      formData.append('addon_price', AddAddons.value.price);
  const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL+'addNewAddonsToMeal/'+mealID,
        headers: { 
          'Authorization': 'Bearer '+userLog.token, 
        },
        data: formData
      };

      try {
        const response = await axios.request(config);
        toast(response.data.message, {
              theme: "colored",
              type: "success",
              position: "top-left",
              autoClose: 3000,
            });
            showAddonswithoutsToMeal.value = true;
            showAddAddonsToMeal.value = false;
            getAddons(AddAddons.value.id);
      } catch (error) {
        toast("Error When Add Addon", {
              theme: "colored",
              type: "error",
              position: "top-left",
              autoClose: 3000,
            });
      }
};


const showAddOfferFunction = async (meal) => {
  showOfferToMeal.value = true;
  OfferToMeal.value.id = meal.id
};

const showEditOfferFunction = async (meal) => {
  showOfferToMeal.value = true;
  showOfferEdit.value = true;
  OfferToMeal.value.id = meal.id
};

const OfferToMealAPI = async (mealID) => {
  const formData = new FormData();
      formData.append('end_date', OfferToMeal.value.date);
      formData.append('price_after_discount', OfferToMeal.value.price);
      let api = "addOfferToMeal";
      if(!showOfferEdit.value)
        api = "editOffer";
  const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL+api+'/'+mealID,
        headers: { 
          'Authorization': 'Bearer '+userLog.token, 
        },
        data: formData
      };

      try {
        const response = await axios.request(config);
        toast(response.data.Result, {
              theme: "colored",
              type: "success",
              position: "top-left",
              autoClose: 3000,
            });
            mealList_ = [];
            getmaels();
            showOfferToMeal.value = false;
            showOfferEdit.value = true;

      } catch (error) {
        let errorMassege = "Errore When Add Offer to Meal";
        if(!showOfferEdit.value)
          errorMassege = "Errore When Edit Offer to Meal";
        toast(errorMassege, {
              theme: "colored",
              type: "error",
              position: "top-left",
              autoClose: 3000,
            });
      }
};








const showDeleteOfferFunction = async (mealid) => {
  showOfferDelete.value = true;
  OfferToDelete.value.id = mealid
};

const deleteOfferAPI = async (mealID) => {
  const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL+'deleteOffer/'+mealID,
        headers: { 
          'Authorization': 'Bearer '+userLog.token, 
        },
      };

      try {
        const response = await axios.request(config);
        toast(response.data, {
              theme: "colored",
              type: "success",
              position: "top-left",
              autoClose: 3000,
            });
            mealList_ = [];
            getmaels();
            showOfferToMeal.value = false;
      } catch (error) {
        toast("Offer Not Deleted", {
              theme: "colored",
              type: "error",
              position: "top-left",
              autoClose: 3000,
            });
      }
};


const deleteMealFunction = (meal) =>{
  showMealDelete.value = true;
  deleteMeal.value.id = meal.id;
  deleteMeal.value.name = meal.name;
};

const deleteMealAPI = async (mealID) =>{
  const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL+'deleteMeal/'+mealID,
        headers: { 
          'Authorization': 'Bearer '+userLog.token, 
        },
      };

      try {
        const response = await axios.request(config);
        toast(response.data.Result, {
              theme: "colored",
              type: "success",
              position: "top-left",
              autoClose: 3000,
            });
            mealList_ = [];
            getmaels();
            showMealDelete.value = false;
      } catch (error) {
        toast("The isn't Deleted", {
              theme: "colored",
              type: "error",
              position: "top-left",
              autoClose: 3000,
            });
      }
};















const editMealFunction = async (meal) => {
  let categoryImage = ref('');
  for(let i=0 ; i<categoryList.value.length ; i++) {
      if(categoryList.value[i].id == meal.category_id) {
        categoryImage.value = categoryList.value[i].URL;
        break;
      }
    }
  saveMeal(meal.name, meal.price, meal.description, meal.URL, meal.category_id, meal.id, meal.price_after_discount,meal.categoryName,categoryImage.value);
  set_mealDataToEditBool(true);
  goAddMealOrEdit();
};




  function getCategoryMeals(meal) {
    for (let i = 0; i< categoryList.value.length; i++)
    {
      if(categoryList.value[i].id == meal.category_id)
      {
        return categoryList.value[i].name;
      }
    }
  };



  const getmaels = async() => {
    getMealBool.value = false;
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL+'MealByResturent',
      headers: { 
        'Authorization': 'Bearer '+userLog.token
      }
    };
    await axios.request(config)
    .then((response) => {
      mealList = response.data;
      for (let i = 0; i< mealList.length; i++)
    {
      let categoryName = getCategoryMeals(mealList[i]);
      let one_mael = { 
        id: mealList[i].id,
        name: mealList[i].name, 
        photo: mealList[i].photo, 
        price: mealList[i].price, 
        description:mealList[i].description, 
        owner_resturent_id: mealList[i].owner_resturent_id,
        category_id: mealList[i].category_id, 
        price_after_discount: mealList[i].price_after_discount, 
        end_date: mealList[i].end_date, 
        categoryName: categoryName, 
        URL: imageURL + mealList[i].photo,
        OfferAdded: true,
        selected: false,
      };
      if(one_mael.end_date == "null")
        one_mael.end_date = null;
      if(one_mael.price_after_discount == "null")
        one_mael.price_after_discount = null;
      if(one_mael.end_date == null)
      one_mael.OfferAdded = false;

      mealList_.push(one_mael);
    }
    mealcount.value = mealList.length;
    getMealBool.value = true;
    })
    .catch(() => {
      toast("Error in get Meals..", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });
    });
  };



  const getAddons = async(id) => {
    AddonsList = [];
    getAddonsBool.value = false;
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL+'showDetailAddionMeal/'+id,
    };
    await axios.request(config)
    .then((response) => {
      AddonsList_ = response.data;
      for (let i = 0; i< AddonsList_.length; i++)
    {
      let one_Addon = { 
        id: AddonsList_[i].id,
        addon: AddonsList_[i].addon, 
        meal_id: AddonsList_[i].meal_id, 
      };

      AddonsList.push(one_Addon);
    }
    Addonscount.value = AddonsList.length;
    getAddonsBool.value = true;
    })
    .catch(() => {
      toast("Error in get Addons..", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });
    });
  };

  const getWithouts = async(id) => {
    WithoutList = [];
    getWithoutBool.value = false;
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL+'showDetailWithoutMeal/'+id,
    };
    await axios.request(config)
    .then((response) => {
      WithoutList_ = response.data;
      for (let i = 0; i< WithoutList_.length; i++)
    {
      let one_Without = { 
        id: WithoutList_[i].id,
        without_name: WithoutList_[i].without_name, 
        meal_id: WithoutList_[i].meal_id, 
      };

      WithoutList.push(one_Without);
    }
    Withoutcount.value = WithoutList.length;
    getWithoutBool.value = true;
    })
    .catch(() => {
      toast("Error in get Withouts..", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });
    });
  };
  



const getCategory = async() => {
    getCategoryBool.value = false;
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL+'getCategoryMyOwner',
      headers: { 
        'Authorization': 'Bearer '+userLog.token
      }
    };
    await axios.request(config)
    .then((response) => {
      categoryList_ = response.data;
      for (let i = 0; i< categoryList_.length; i++)
    {
      let one_category = { 
        id: categoryList_[i].id,
        name: categoryList_[i].name, 
        photo: categoryList_[i].photo, 
        URL: imageURL + categoryList_[i].photo,
      };
      categoryList.value.push(one_category);
    }
    categorycount.value = categoryList_.length;
    getCategoryBool.value = true;
    })
    .catch(() => {
      toast("Error in get Category..", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });

    });
  };

  getCategory();
  getmaels();



  let offerPackageToEditID = computed(() => get_id_packegeOffer_toEdit());
let boolFoundIDs = computed(() => get_packageOfferToEditBool());
if (boolFoundIDs.value && offerPackageToEditID.value > -1){
  const axios = require('axios');

let config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: BaseURL+'detailOfferPackage/'+ offerPackageToEditID.value,
  headers: { 
    'Authorization': 'Bearer '+userLog.token,
  }
};

axios.request(config)
.then((response) => {
  console.log("response.data.length,mealList_.length")
  console.log(response.data.length,mealList_.length)
  for (var i = 0; i < response.data.length; i++){
    for (var j = 0; j < mealList_.length; j++) {
      console.log(mealList_[j].id,response.data[i].id)
      if(mealList_[j].id == response.data[i].id) {
        
        mealList_[j].selected = true;
        let one_mael = { 
        id: mealList_[j].id,
        name: mealList_[j].name, 
        URL: mealList_[j].URL,
        }
        mealsID.value.push({ mealId: mealList_[j].id });
        selectedMeals.value.push(mealList_[j].id);
        selectedMealsData.value.push(one_mael);
        if(mealsID.value.length > 0){
          showButtonAddPackegeOffers.value = true;
        }else {
          showButtonAddPackegeOffers.value = false;
        }
      }
    }
  }
  
})
.catch(() => {
  toast("Error in get Backae Offer Detail..", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });
});

}


</script>


<style>
.contenerLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 500px;
}


.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid rgb(251, 99, 64);
  border-bottom: 4px solid rgb(251, 99, 64);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader2 {
  top: 50%;
  left: 50%;
  z-index: 1;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid rgb(251, 99, 64);
  border-bottom: 4px solid rgb(251, 99, 64);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.container {
  display: flex;
  justify-content: flex-end; /* يضع العنصر في أقصى اليسار */
  align-items: center;
  padding-left: 20rem; /* إضافة مسافة صغيرة على اليسار لضمان عدم تلاصقه مع الحافة */
}

.add-button {
  justify-content: flex-start; /* يضع العنصر في أقصى اليسار */

  background-color: transparent; /* يمكنك تغيير اللون كما تحب */
  border: none; /* إزالة الحدود الافتراضية للزر */
  color: white; /* لون الأيقونة */
  font-size: 2rem; /* حجم الأيقونة */
  cursor: pointer; /* تغيير المؤشر عند التحويم */
  display: flex;
  padding-left: 4rem; 
}

.add-to_package {
  justify-content: flex-start; 
  border: none; 
  color: rgb(135, 175, 186); 
  font-size: 1.2rem; 
  cursor: pointer; 
  display: flex;
  padding-left: 1rem; 
}
.add-to_package2 {
  justify-content: flex-start; 
  border: none; 
  color: #ff8f07; 
  font-size: 1.5rem; 
  cursor: pointer; 
  display: flex;
  padding-left: 1rem; 
}

.add-button i {
  font-size: 2rem;
}
.file-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    background-color: #f9f9f9;
}


.imageInput {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: #f9f9f9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.imageInput:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.picture-input {
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.3s ease;
}

.picture-input:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.btn {
    background-color: #ffae00;
    color: #fff;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #0056b3;
}

.bmodal {
  background-color: #fff;
  padding: 20px;
  border: 2px solid rgb(5, 250, 238); /* يمكنك تغيير اللون والسماكة حسب الرغبة */
  border-radius: 10px; /* يمكنك تعديل الزوايا المدورة حسب الرغبة */
  padding: 1rem;
  margin-bottom: 1rem;
}

.bbutton {
  margin: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.bbutton:hover {
  transform: scale(1.05);
}

.bformgroup {
  margin-bottom: 20px;
}

.bforminput, .b-form-file,.bformtextarea {
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ffae00;
  transition: border-color 0.3s ease;
}

.bforminput1 {
  border-radius: 8px;
  padding: 5%;
  border: 1px solid #ffae00;
  transition: border-color 0.3s ease;
}

.bforminput:focus, .bformfile:focus,.bformtextarea:focus {
  border-color: #ffae00;
  box-shadow: 0 0 5px #ffae00;
}



.container-side-by-side { 
	display: flex; 
	flex-direction: row; 
	flex-wrap: wrap; 
} 

.card1 {
  width: 100%;
  max-width: 90%; /* زيادة الحد الأقصى للعرض */
  margin: 0 auto; /* لضمان أن البطاقة تكون في الوسط */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #faf0e4;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card1:hover {
  transform: scale(1.01);
}




.description-text {
  max-width: 300px; /* يمكنك تعديل هذا العرض حسب الحاجة */
  word-wrap: break-word; /* لتقسيم الكلمات الكبيرة */
  overflow-wrap: break-word; /* لدعم المتصفحات الحديثة */
  white-space: normal; /* للسماح بالالتفاف على الأسطر المتعددة */
}


.custom-button {
  border: none; /* إزالة الحواف */
  cursor: pointer; /* تغيير المؤشر عند التمرير على الزر */
  transition: transform 0.2s ease-in-out; /* إضافة حركة بسيطة */
}

.custom-button:hover {
  transform: scale(1.05); /* تكبير الزر قليلاً عند التمرير عليه */
}


.avatar-lg {
  width: 100%; /* تعيين العرض ليكون 100% */
  max-width: 300px; /* أقصى عرض للصورة */
  height: auto; /* تعيين الارتفاع ليكون تلقائياً */
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 15px;
}

.custom-avatar {
    width: 200px;
    height: 200px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.meal-item_packege {
    margin-bottom: 20px; /* يمكنك تعديل القيمة كما تريد */
    padding-left:10%;
}

.divcorsor{
  cursor: pointer;
}


.delete-text{
  color: #ff6e41;
  
  line-height: 1.8;
  font-family: "Times New Roman", Times, serif;
  font-style: oblique;
  font-weight: bold;
  font-size: 22px;
}


.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* لضبط المسافة العلوية إذا لزم الأمر */
}

.button-group .bbutton {
  margin: 0 10px; /* لضبط المسافة الأفقية بين الأزرار */
}


.styled-date-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.styled-date-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-group .bbutton {
  margin: 0 2px;
}


#hiddenElement {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
}

.vertical-line {
    width: 2px; /* عرض الخط */
    height: 40%; /* طول الخط يساوي ارتفاع الشاشة بالكامل */
    background-color: rgb(158, 158, 158); /* لون الخط */
    position: absolute;
    left: 50%; /* موضع الخط في منتصف الصفحة */
    transform: translateX(-50%);
}


.textAddon .textAddonprice{
  color: #ff8c00;
  display: inline-block;
  max-width: none;
}

.textAddonprice{
  color: #ff8c00;
}

.textWithout{
color: #630d1d;
}

.table {
  width: auto;
}
th, td {
    white-space: nowrap;
}
.tdAddon{
  padding-right: 20%;
}

.tdAddon2{
  padding-left: 20%;
}

@keyframes shakeAndGrow {
      0%, 100% {
        transform: translateX(0) scale(1);
      }
      10%, 30%, 50%, 70%, 90% {
        transform: translateX(-10px) scale(1.1);
      }
      20%, 40%, 60%, 80% {
        transform: translateX(10px) scale(0.9);
      }
    }

    .animated-icon {
      display: inline-block;
      animation: shakeAndGrow 500s infinite;
    }

    .custom-checkbox {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .custom-checkbox:checked {
      background-color: #ff8f07;
      color: #ff8f07;
    }

    .custom-checkbox:checked:after {
      width: 5px;
      height: 10px;
      background-color: #ff8f07;
    }

    .selected-row {
      background-color: #ffcf95;
      border-radius: 20px;
    }
    .centered {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999; /* قيمة عالية لضمان عرضه فوق العناصر الأخرى */
    background-color: rgba(83, 78, 78, 0.4); /* خلفية شفافة */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* ظل لإضافة تأثير تشويه الواجهة */
}

.toRTL{
  float: right;
  
  border: none; /* إزالة الحواف */
  cursor: pointer; /* تغيير المؤشر عند التمرير على الزر */
  transition: transform 0.2s ease-in-out; /* إضافة حركة بسيطة */
}


.toRTL:hover {
  transform: scale(1.05); /* تكبير الزر قليلاً عند التمرير عليه */
}

.custom-bg {
  background-color: #faebbc;
  border: 2px solid #ff8f07;
  color: #FFC107;
}
.addDiv{
  padding-top: 0%;
  padding-right: 5%;
  padding-bottom: 0%;
  padding-left: 00%;

}
.packageDiv{
  padding-top: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  padding-left: 30%;
}
.editDiv{
  padding-top: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  padding-left: 22%;
}

.table-container {
  max-height: 480px; /* ارتفاع ثابت للعنصر الذي يحتوي على الجدول */
  overflow-y: auto; /* تمكين التمرير العمودي */
  border: 2px solid #ff8c00; /* حدود الاختياري للعنصر */
  border-radius: 5px; /* حدود دائرية للاختياري */
}

.section-container {
  max-height: 360px; /* ارتفاع ثابت للعنصر الذي يحتوي على الجدول */
  min-height:100px;
  overflow-y: auto; /* تمكين التمرير العمودي */
  border: 2px solid #ff8c00; /* حدود الاختياري للعنصر */
  border-radius: 10px;
  margin: 1%;
}

.package-container {
  max-height: 360px; /* ارتفاع ثابت للعنصر الذي يحتوي على الجدول */
  min-height:100px;
  overflow-y: auto; /* تمكين التمرير العمودي */
}

.meal-table {
  width: 100%; /* عرض الجدول ليأخذ العرض الكامل للحاوية */
  border-collapse: collapse; /* دمج الحدود لتفادي تكرارها */
}


.meal-table thead {
  background-color: #ff8c00; /* لون خلفية رؤوس الأعمدة */
}

.imgdetailpaddig{
  padding-top: 5%;
  padding-right: 0%;
  padding-bottom: 5%;
  padding-left: 5%;
}

.noAddonText{
  line-height: 1.8;
  font-family: "Times New Roman", Times, serif;
  font-style: oblique;
  font-weight: bold;
}

.padding-bottom-5{
  padding-bottom: 50%;
}
</style>