
<script setup>
import { /*computed,*/ ref } from "vue";
import 'vue-select/dist/vue-select.css';
//import { userLog, BaseURL } from './views/DATA/login';

import { computed } from "vue";
import { useStore } from "vuex";
import Configurator from "@/examples/Configurator.vue";
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
//import AppFooter from "@/examples/Footer.vue";
import Signin from "./views/SignIn.vue";
import { set_fcm  } from './views/DATA/login'

import 'vue3-toastify/dist/index.css';

//import { loginBool } from './views/DATA/login';
import sendlocation from './components/sendLocation.vue';
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: 'AIzaSyBwXHl16oTlQM4JETamUkPO6SJwA51uxUM',
  authDomain: 'deliveryapp-16e35.firebaseapp.com',
  projectId: 'deliveryapp-16e35',
  storageBucket: 'deliveryapp-16e35.appspot.com',
  messagingSenderId: '445752029618',
  appId: '1:445752029618:web:c764f00308cbf3e297b4ab',
  measurementId: 'G-JRJS412TKG',
};

const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);
  // Request permission to receive notifications
  Notification.requestPermission().then(function(permission) {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // Get FCM token
      getToken(messaging, { vapidKey: 'BClQZ5zAvTfR3YazGM4JP4AMj1NW_4_hZIYsSrb7L0QCtwJA4UeZatBNY_sYmxG-yXlWCVYybDzAYNmbisxgMmU' }).then((currentToken) => {
        if (currentToken) {
          console.log('FCM Token:', currentToken);
          // Send the token to your server or save it in the localStorage
          set_fcm(currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
    } else {
      console.log('Unable to get permission to notify.');
    }
  })
 




const store = useStore();
const isNavFixed = computed(() => store.state.isNavFixed);
const darkMode = computed(() => store.state.darkMode);
const isAbsolute = computed(() => store.state.isAbsolute);
const showSidenav = computed(() => store.state.showSidenav);
const layout = computed(() => store.state.layout);
const showNavbar = computed(() => store.state.showNavbar);
//const showFooter = computed(() => store.state.showFooter);
const showConfig = computed(() => store.state.showConfig);
const hideConfigButton = computed(() => store.state.hideConfigButton);
const toggleConfigurator = () => store.commit("toggleConfigurator");

const navClasses = computed(() => {
  return {
    "position-sticky bg-white left-auto top-2 z-index-sticky":
      isNavFixed.value && !darkMode.value,
    "position-sticky bg-default left-auto top-2 z-index-sticky":
      isNavFixed.value && darkMode.value,
    "position-absolute px-4 mx-0 w-100 z-index-2": isAbsolute.value,
    "px-0 mx-4": !isAbsolute.value,
  };
});
let loginBool_ = computed(() => store.state.login);
let notfoundAddress = computed(() => store.state.notfoundAddress);

let latitude = ref(0.0);
let longitude = ref(0.0);

const success = (position) => {
    latitude.value  = position.coords.latitude;
    longitude.value = position.coords.longitude;
    console.log(latitude.value);
    console.log(longitude.value);
    // Do something with the position
};

const error = (err) => {
    console.log("ERROR GET Location",err)
};

// This will open permission popup
navigator.geolocation.getCurrentPosition(success, error);







    
</script>



<template>
<div :style="{
  backgroundImage: 'url(\'' + require('@/assets/img/resturent/background1.jpg') + '\')',
  backgroundPosition: 'center center',  /* ضع الصورة في وسط العنصر */
  backgroundSize: 'cover',              /* اجعل الصورة تغطي العنصر بالكامل */
  backgroundRepeat: 'no-repeat',  
  height: '100vh',                 
  width: '100%',
  display: 'block'            
}">
  <span class="mask bg-gradient-warning opacity-0"></span>
  <div v-if="loginBool_ == true">
    <div v-if="notfoundAddress == false">
      <div
        v-show="layout === 'landing'"
        class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
      ></div>

      <sidenav v-if="showSidenav" />

      <main
        class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
      >
        <!-- nav -->

        <navbar :class="[navClasses]" v-if="showNavbar" />

        <router-view />

        <!--<app-footer v-show="showFooter" />-->

        <configurator
          :toggle="toggleConfigurator"
          :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
        />
      </main>
    </div>
    <div v-else>
      <sendlocation :visible="notfoundAddress" :x="latitude" :y="longitude"/>
      
    </div>
  </div>

  <div v-else>
    <Signin/>
  </div>

</div>
</template>
<style>

.bformgroup {
  margin-bottom: 20px;
}

.bforminput, .b-form-file,.bformtextarea {
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ffae00;
  transition: border-color 0.3s ease;
}

.bforminput:focus, .bformfile:focus,.bformtextarea:focus {
  border-color: #d39000;
  box-shadow: 0 0 5px #ffae00;
}

</style>