import { ref } from 'vue';

class UserData {
  constructor(blocked_until,cancel_count, created_at, email, email_verified_at, id, name, phone, status, updated_at) {
    this.blocked_until = blocked_until;
    this.cancel_count = cancel_count;
    this.created_at = created_at;
    this.email = email;
    this.email_verified_at = email_verified_at;
    this.id = id;
    this.name = name;
    this.phone = phone;
    this.status = status;
    this.updated_at = updated_at;
    this.name = name;

  }

}



class UserClass {
  constructor(token, blocked_until,cancel_count, created_at, email, email_verified_at, id, name, phone, status, updated_at) {
    this.token = token;
    this.userdata = new UserData(blocked_until,cancel_count, created_at, email, email_verified_at, id, name, phone, status, updated_at);
  }
  get_data_user_from_login(token, blocked_until,cancel_count, created_at, email, email_verified_at, id, name, phone, status, updated_at){
    this.token = token;
    this.userdata = new UserData(blocked_until,cancel_count, created_at, email, email_verified_at, id, name, phone, status, updated_at);
    return this;
  }
}






let userLog = new UserClass();


function save (userLogfromlog) {
  userLog = userLogfromlog;

}

function test () {
  console.log("userAllData 3");
  console.log(userLog);
}



let loginBool = false;


function saveLoginBool (loginBoolin) {
  loginBool = loginBoolin;
}




let boolFoundIDs = ref(false);
const get_boolFoundIDs = () => {return boolFoundIDs;};
const set_boolFoundIDs = (token) => {boolFoundIDs.value = token;};

let offerPackageToEditID = ref(-1);
const get_offerPackageToEditID = () => {return offerPackageToEditID;};


function savePackagesID (selected) {
  offerPackageToEditID.value = selected;
  if (offerPackageToEditID.value > 0) {
    boolFoundIDs.value = true;
  }
}


function saveboolFoundIDs (value) {
    boolFoundIDs.value = value;

}



class FCM{
  constructor(token){
    this.fcm_token = token;
  }
}
let fcm = new FCM('');
const get_fcm = () => {return fcm.fcm_token;}
const set_fcm = (token) => {fcm = new FCM(token);}


//add Or Edit Meal
class mealDataToEditClass {
  constructor(name,price, description, image, category, id, price_after_discount, categoryName, categoryImage) {
    this.name = name;
    this.price = price;
    this.description = description;
    this.image = image;
    this.id = id;
    this.category = category;
    this.price_after_discount = price_after_discount;
    this.categoryName = categoryName;
    this.categoryImage = categoryImage;
  }
}

let mealDataToEdit = new mealDataToEditClass();
let mealDataToEditBool = ref(false);

const get_mealDataToEditBool = () => {return mealDataToEditBool.value;}
const set_mealDataToEditBool = (value) => {mealDataToEditBool.value = value;}

function saveMeal (name,price, description, image, category, id, price_after_discount, categoryName, categoryImage) {
  mealDataToEdit = new mealDataToEditClass(name,price, description, image, category, id, price_after_discount, categoryName, categoryImage);
}




//packegeOffer
class NewpackageOffers {
  constructor(name,meal_ids, discount, end_date, photo) {
    this.name = name;
    this.meal_ids = meal_ids;
    this.discount = discount;
    this.end_date = end_date;
    this.photo = photo;
  }
};

let newpackageOffersdata = new NewpackageOffers();
let packageOfferToEditBool = ref(false);

const get_packageOfferToEditBool = () => {return packageOfferToEditBool.value;}
const set_packageOfferToEditBool = (value) => {packageOfferToEditBool.value = value;}

function savenewpackageOffers (name,meal_ids, discount, end_date, photo) {
  newpackageOffersdata = new NewpackageOffers(name,meal_ids, discount, end_date, photo);
}



let mealsID = ref([])
let selectedMeals = ref([])
let selectedMealsData = ref([]);

const  set_mealsID = (mealsIDArray) => {
  mealsID.value = mealsIDArray;
};
const  set_selectedMeals = (selectedMealsArray) => {
  selectedMeals.value = selectedMealsArray;
};
const  set_selectedMealsData = (selectedMealsDataArray) => {
  selectedMealsData.value = selectedMealsDataArray;
};

const  get_mealsID = () => {
  return mealsID;
};
const  get_selectedMeals = () => {
  return selectedMeals;
};
const  get_selectedMealsData = () => {
  return selectedMealsData ;
};


let id_packegeOffer_toEdit = ref(-1);

const get_id_packegeOffer_toEdit = () => {return id_packegeOffer_toEdit.value;}
const set_id_packegeOffer_toEdit = (value) => {id_packegeOffer_toEdit.value = value;}

const imageURL= 'https://5delivery.store/DeliveryApp/public/upload/';
const BaseURL = 'https://5delivery.store/DeliveryApp/public/api/auth/';
const API_KEY = 'AIzaSyCszZART8MQD_XXPht4z3F8BQRqQn2Ti38'

//const imageURL= 'http://localhost:8000/upload/';
//const BaseURL = 'http://localhost:8000/api/auth/';

export{UserClass, save, test, BaseURL,loginBool,saveLoginBool, userLog, imageURL, API_KEY,
  offerPackageToEditID, savePackagesID, boolFoundIDs, saveboolFoundIDs,
  saveMeal, mealDataToEdit, get_mealDataToEditBool, set_mealDataToEditBool,
  set_fcm, get_fcm,
  set_mealsID, set_selectedMeals, set_selectedMealsData, set_packageOfferToEditBool,
  get_mealsID, get_selectedMeals, get_selectedMealsData,get_packageOfferToEditBool,
  newpackageOffersdata, savenewpackageOffers, set_id_packegeOffer_toEdit, get_id_packegeOffer_toEdit,
  get_offerPackageToEditID, set_boolFoundIDs, get_boolFoundIDs
  
}
