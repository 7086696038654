<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import SidenavList from "./SidenavList.vue";
//import logo from "@/assets/img/logo-ct-dark.png";
//import logoWhite from "@/assets/img/logo-ct.png";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const layout = computed(() => store.state.layout);
const sidebarType = computed(() => store.state.sidebarType);
const darkMode = computed(() => store.state.darkMode);
const sidebartextcolor = computed(() => store.state.sidebartextcolor);
</script>
<template>
  <div
    v-show="layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${darkMode ? 'bg-transparent' : 'bg-white'}`"
  />

  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="`${isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'}    
      ${
        layout === 'landing' ? 'bg-transparent shadow-none' : ' '
      } ${sidebarType}`"
    id="sidenav-main"
    data-color="warning"
  >
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>

      <router-link class="m-0 navbar-brand" to="/">
        <div class="container-side-by-side">
          <img
            v-if="sidebartextcolor == 'text-warning'"
            src="@/assets/img/resturent/listIcon.png"
            alt="main_logo" style="width: 30px; height: 30px"
          />
          <img
            v-if="sidebartextcolor == 'text-success'"
            src="@/assets/img/resturent/listIcon2.png"
            alt="main_logo" style="width: 30px; height: 30px"
          />
          <img
            v-if="sidebartextcolor == 'text-danger'"
            src="@/assets/img/resturent/listIcon1.png"
            alt="main_logo" style="width: 30px; height: 30px"
          />
          <img
            v-if="sidebartextcolor == 'text-black'"
            src="@/assets/img/resturent/listIcon4.png"
            alt="main_logo" style="width: 30px; height: 30px"
          />
          <img
            v-if="sidebartextcolor == 'text-info'"
            src="@/assets/img/resturent/listIcon3.png"
            alt="main_logo" style="width: 30px; height: 30px"
          />
          <img
            v-if="sidebartextcolor == 'text-primary'"
            src="@/assets/img/resturent/listIcon5.png"
            alt="main_logo" style="width: 30px; height: 30px"
          />

          <div class="ms-2 font-weight-bold me-2" :class="sidebartextcolor">Resturent Dashboard</div>
        </div>
      </router-link>
    </div>

    <hr class="mt-0 horizontal dark" />

    <sidenav-list />
  </aside>
</template>
<style>
.container-side-by-side { 
	display: flex; 
	flex-direction: row; 
	flex-wrap: wrap; 
} 
</style>