<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const sidebartextcolor = computed(() => store.state.sidebartextcolor);
const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};
</script>
<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : sidebartextcolor"
          :navText="isRTL ? 'الطلبات' : 'Orders'"
        >
          <template v-slot:icon>
            <i class="pi pi-address-book" :class="getRoute() === 'dashboard' ? 'active' : sidebartextcolor" style="font-size: 1.1rem"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/tables"
          :class="getRoute() === 'tables' ? 'active' : sidebartextcolor"
          :navText="isRTL ? 'الوجبات' : 'Males'"
        >
          <template v-slot:icon>
            <i class="pi pi-shop" :class="getRoute() === 'tables' ? 'active' : sidebartextcolor" style="font-size: 1.1rem"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/packageOffers"
          :class="getRoute() === 'packageOffers' ? 'active' : sidebartextcolor"
          :navText="isRTL ? 'العروض' : 'My Offers'"
        >
        <template v-slot:icon>
            <i class="pi pi-slack" :class="getRoute() === 'packageOffers' ? 'active' : sidebartextcolor" style="font-size: 1.1rem"></i>
          </template>
        </sidenav-item>
      </li>


      <li class="nav-item">
        <sidenav-item
          to="/Advertisement"
          :class="getRoute() === 'Advertisement' ? 'active' : sidebartextcolor"
          :navText="isRTL ? 'الإعلانات' : 'Advertisements'"
        >
        <template v-slot:icon>
            <i class="pi pi-objects-column" :class="getRoute() === 'Advertisement' ? 'active' : sidebartextcolor" style="font-size: 1.1rem"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/category"
          :class="getRoute() === 'category' ? 'active' : sidebartextcolor"
          :navText="isRTL ? 'التصنيفات' : 'Category'"
        >
        <template v-slot:icon>
            <i class="pi pi-sitemap" :class="getRoute() === 'category' ? 'active' : sidebartextcolor" style="font-size: 1.1rem"></i>
          </template>
        </sidenav-item>
      </li>


      


      <li class="nav-item">
        <sidenav-item
          to="/billing"
          :class="getRoute() === 'billing' ? 'active' : sidebartextcolor"
          :navText="isRTL ? 'الفواتیر' : 'Billing'"
        >
          <template v-slot:icon>
            <i class="pi pi-chart-line " :class="getRoute() === 'billing' ? 'active' : sidebartextcolor" style=" font-size: 1.1rem"></i>
          </template>
        </sidenav-item>
      </li>


      <li class="mt-3 nav-item">
        <h6
          v-if="isRTL"
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          صفحات الإضافة
        </h6>

        <h6
          v-else
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          Adding
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/addmeal"
          :class="getRoute() === 'addmeal' ? 'active' : sidebartextcolor"
          :navText="isRTL ? 'لإضافة وجبة' : 'Add Meal'"
        >
          <template v-slot:icon>
            <i class="pi pi-plus-circle " :class="getRoute() === 'addmeal' ? 'active' : sidebartextcolor" style="font-size: 1.1rem"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/addPackageOffer"
          :class="getRoute() === 'addPackageOffer' ? 'active' : sidebartextcolor"
          :navText="isRTL ? 'لإضافة عرض' : 'Add Packege Offer'"
        >
          <template v-slot:icon>
            <i class="pi pi-slack " :class="getRoute() === 'addPackageOffer' ? 'active' : sidebartextcolor" style=" font-size: 1.1rem"></i>
          </template>
        </sidenav-item>
      </li>


    </ul>
  </div>
</template>

<script>
import 'primeicons/primeicons.css'



</script>