<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card1 my-4">
          <div class="card-header p-0 position-relative mt-n5 mx-3 z-index-2">
            <div class="custom-bg shadow-success border-radius-lg pt-4 pb-3">
              <div class="ms-auto">  
                <div class="d-flex flex-row justify-content-between">
                  <h6 class="text-warning text-capitalize ps-3 me-8"> Add Advertisement:</h6>
                  <div style="padding-right:5%">
                    <button class=" toRTL badge badge-lg bg-gradient-warning" @click="uploadAdvertisement">
                      Send Advertisement To Admin
                      <i class="pi pi-send"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
            <br>
            <br>
              <b-form @submit.prevent="uploadAdvertisement" class="bform">
                <div class='container-side-by-side'> 
                  <div class="col-6">
                    <div class='custom-bg shadow-success border-radius-lg pt-4 pb-3'> 
                      <div style="padding-left: 5%; padding-right: 5%;">
                        <b-form-group label="Advertisement Name" class="bformgroup">
                          <b-form-input v-model="newAdvertisement.name" required class="bforminput"></b-form-input>
                        </b-form-group>
                      </div>
                      <div style="padding-left: 5%; padding-right: 5%;">
                        <b-form-group label="Meal or Offer" class="">
                          <div class='container-side-by-side'> 
                            <div class="dropdown">
                              <button class="dropbtn me-2">
                                <i :class="newAdvertisement.icontype" style="color: warning; font-size: 1.5rem">  {{ newAdvertisement.type }}</i> 
                              </button>
                              <div class="dropdown-content">
                                <div class="category-item" @click="setType('offer')">
                                  <i class="pi pi-gift" style="color: warning; font-size: 1.2rem"></i>
                                  Offer
                                </div>
                                <div class="category-item" @click="setType('meal')">
                                  <i class="pi pi-shop" style="color: warning; font-size: 1.2rem"></i>
                                  Meal
                                </div>
                              </div>
                            </div>
                          </div>
                          <br>
                          <b-form-group :label="newAdvertisement.type == 'offer'? 'choice Offer': 'choice Meal'" class="">
                            <div v-if="newAdvertisement.type == 'meal'" class="meal-container">
                              <div v-for="meal in mealList_" :key="meal.id" class="meal-item">
                                <input type="radio" :id="meal.name" name="meal" :value="meal.id" v-model="newAdvertisement.id">
                                <label for="meal">
                                  <img :src=" meal.URL" class="avatar avatar-sm me-3 border-radius-lg"
                                  alt="Meal" />
                                  {{ meal.name }}
                                </label>
                              </div>
                            </div>
                            <div v-else class="meal-container">
                              <div v-for="packageOffer in packageOffersList_" :key="packageOffer.id" class="meal-item">
                                <input type="radio" :id="packageOffer.name" name="Offer" :value="packageOffer.id" v-model="newAdvertisement.id">
                                <label for="packageOffer">
                                  <img :src=" packageOffer.URL" class="avatar avatar-sm me-3 border-radius-lg"
                                  alt="packageOffer" />
                                  {{ packageOffer.name }}
                                </label>
                              </div>
                            </div>
                          </b-form-group>
                        </b-form-group>
                      </div>
                      </div>
                    </div>
                  <div class="col-6">
                    <div class='custom-bg shadow-success border-radius-lg pt-4 pb-3'>
                    <b-form-group label="Advertisement Image" class="bformgroup">
                      <b-form-file @change="onAdvertisementFileChange" class="bformfile"></b-form-file>
                    </b-form-group>
                    <div class="imageInput">    
                      <picture-input 
                        ref="pictureInput"
                        width="400" 
                        height="400" 
                        margin="20" 
                        accept="image/jpeg,image/png" 
                        size="10" 
                        button-class="btn"
                        :custom-strings="{
                          upload: '<h1>Bummer!</h1>',
                          drag: 'Drag an Image or Click to select Image from Files'
                        }"
                        @change="onAdvertisementFileChange">
                      </picture-input>
                    </div>
                  </div>
                  </div>
                    
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>








    </div>
  </div>
</template>

<script setup>

import 'vue-select/dist/vue-select.css';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { BaseURL, userLog ,imageURL } from './DATA/login';
import { ref  } from 'vue';
import PictureInput from 'vue-picture-input'






const axios = require('axios');


//let Advertisementcount  = ref(0); 


//let AdvertisementList = [];
//let AdvertisementList_ = [];



//let getAdvertisementBool = ref(false);

let showAdvertisementModal=  ref(false);







let newAdvertisement =  ref({
  id:'',
  name: '',
  photo: null,
  type:'type',
  icontype: '',
});

const setType = (type) => {
  newAdvertisement.value.type = type;
  newAdvertisement.value.id = '';
  if(type == "meal")
    newAdvertisement.value.icontype = "pi pi-shop";
  else
    newAdvertisement.value.icontype = "pi pi-gift";
};












function onAdvertisementFileChange(event) {
      newAdvertisement.value.photo = event.target.files[0];
    };



const uploadAdvertisement = async() => {
     
      const formData = new FormData();
      formData.append('name', newAdvertisement.value.name);
      formData.append('photo', newAdvertisement.value.photo);
      formData.append('type', newAdvertisement.value.type);
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL+'addAdvertisement/'+newAdvertisement.value.id,
        headers: { 
          'Authorization': 'Bearer '+userLog.token, 
        },
        data: formData
  };
      try {
        const response = await axios.request(config);
        console.log(response.data);
        toast(response.data, {
          theme: "colored",
          type: "success",
          position: "top-left",
          autoClose: 3000,
        });
        
        //getAdvertisement();
        showAdvertisementModal.value = false;
      } catch (error) {
        toast(error.messege, {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });
        console.error('Error uploading Advertisement:', error);
      }
    };
    
    
    




/*

  const getAdvertisement = async() => {
    getAdvertisementBool.value = false;
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL+'getAdvertisementMyOwner',
      headers: { 
        'Authorization': 'Bearer '+userLog.token
      }
    };
    await axios.request(config)
    .then((response) => {
      AdvertisementList_ = response.data;
      for (let i = 0; i< AdvertisementList_.length; i++)
    {
      let one_Advertisement= { 
        id: AdvertisementList_[i].id,
        name: AdvertisementList_[i].name, 
        photo: AdvertisementList_[i].photo, 
        URL: imageURL + AdvertisementList_[i].photo,
      };
      AdvertisementList.push(one_Advertisement);
    }
    Advertisementcount.value = AdvertisementList_.length;
    getAdvertisementBool.value = true;
    })
    .catch((error) => {
      toast("Error in get Advertisement..", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });

      console.log(error);
    });
  };
*/

  let getMealBool = ref(false);
  let mealcount  = ref(0); 
  let mealList = [];
  let mealList_ = [];

  const getmaels = async() => {
    getMealBool.value = false;
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL+'MealByResturent',
      headers: { 
        'Authorization': 'Bearer '+userLog.token
      }
    };
    await axios.request(config)
    .then((response) => {
      mealList = response.data;
      for (let i = 0; i< mealList.length; i++)
    {
      let one_mael = { 
        id: mealList[i].id,
        name: mealList[i].name, 
        photo: mealList[i].photo, 
        URL: imageURL + mealList[i].photo,
      };
      mealList_.push(one_mael);
    }
    mealcount.value = mealList.length;
    getMealBool.value = true;
    })
    .catch((error) => {
      toast("Error in get Meals..", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });
      console.log(error);
    });
  };



  let getpackageOffersBool = ref(false);
  let resturantID = ref(0);
  let packageOfferscount  = ref(0); 
  let packageOffersList = [];
  let packageOffersList_ = [];

  const getpackageOffers = async() => {
    let config_toc = {
        method: 'get',
        maxBodyLength: Infinity,
        url: BaseURL+'GetIdResturentByToken',
        headers: { 
          'Authorization': 'Bearer '+userLog.token
        }
      };
      await axios.request(config_toc)
      .then((response) => {
        resturantID.value = response.data
      })
      .catch((error) => {
        console.log(error);
      });
    getpackageOffersBool.value = false;
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL+'allOfferPackage/'+ resturantID.value,
      headers: { 
        'Authorization': 'Bearer '+userLog.token
      }
    };
    await axios.request(config)
    .then((response) => {
      packageOffersList = response.data;
      for (let i = 0; i< packageOffersList.length; i++)
    {
      let one_packageOffers = { 
        id: packageOffersList[i].id,
        name: packageOffersList[i].name, 
        photo: packageOffersList[i].photo, 
        URL: imageURL + packageOffersList[i].photo,
      };

      packageOffersList_.push(one_packageOffers);
    }
    packageOfferscount.value = packageOffersList.length;
    getpackageOffersBool.value = true;
    })
    .catch((error) => {
      toast("Error in get packageOffers..", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });
      console.log(error);
    });
  };

  //getAdvertisement();
   getmaels();
   getpackageOffers();

</script>


<style>


.contenerLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 500px;
}


.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid rgb(251, 99, 64);
  border-bottom: 4px solid rgb(251, 99, 64);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader2 {
  top: 50%;
  left: 50%;
  z-index: 1;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid rgb(251, 99, 64);
  border-bottom: 4px solid rgb(251, 99, 64);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.container {
  display: flex;
  justify-content: flex-end; /* يضع العنصر في أقصى اليسار */
  align-items: center;
  padding-left: 20rem; /* إضافة مسافة صغيرة على اليسار لضمان عدم تلاصقه مع الحافة */
}

.add-button {
  justify-content: flex-start; /* يضع العنصر في أقصى اليسار */

  background-color: transparent; /* يمكنك تغيير اللون كما تحب */
  border: none; /* إزالة الحدود الافتراضية للزر */
  color: white; /* لون الأيقونة */
  font-size: 2rem; /* حجم الأيقونة */
  cursor: pointer; /* تغيير المؤشر عند التحويم */
  display: flex;
  padding-left: 4rem; 
}


.add-button i {
  font-size: 2rem;
}
.file-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    background-color: #f9f9f9;
}


.imageInput {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: #f9f9f9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.imageInput:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.picture-input {
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.3s ease;
}

.picture-input:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.btn {
    background-color: #ffae00;
    color: #fff;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #0056b3;
}

.bmodal {
  background-color: #fff;
  padding: 20px;
  border: 2px solid rgb(5, 250, 238); /* يمكنك تغيير اللون والسماكة حسب الرغبة */
  border-radius: 10px; /* يمكنك تعديل الزوايا المدورة حسب الرغبة */
  padding: 1rem;
  margin-bottom: 1rem;
}

.bbutton {
  margin: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.bbutton:hover {
  transform: scale(1.05);
}

.bformgroup {
  margin-bottom: 20px;
}

.bforminput, .b-form-file,.bformtextarea {
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ffae00;
  transition: border-color 0.3s ease;
}

.bforminput:focus, .bformfile:focus,.bformtextarea:focus {
  border-color: #ffae00;
  box-shadow: 0 0 5px #ffae00;
}



.container-side-by-side { 
	display: flex; 
	flex-direction: row; 
	flex-wrap: wrap; 
} 

.card1 {
  width: 100%;
  max-width: 90%; /* زيادة الحد الأقصى للعرض */
  margin: 0 auto; /* لضمان أن البطاقة تكون في الوسط */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #f9f9f9;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card1:hover {
  transform: scale(1.01);
}


.cardadd1 {
  width: 40%;
  max-width: 40%; /* زيادة الحد الأقصى للعرض */
  margin: 0 auto; /* لضمان أن البطاقة تكون في الوسط */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #f9f9f9;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cardadd1:hover {
  transform: scale(1.01);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.description-text {
  max-width: 300px; /* يمكنك تعديل هذا العرض حسب الحاجة */
  word-wrap: break-word; /* لتقسيم الكلمات الكبيرة */
  overflow-wrap: break-word; /* لدعم المتصفحات الحديثة */
  white-space: normal; /* للسماح بالالتفاف على الأسطر المتعددة */
}

.modal-header {
  background-color: rgb(5, 250, 238);
  color: white;
}

.custom-button {
  border: none; /* إزالة الحواف */
  cursor: pointer; /* تغيير المؤشر عند التمرير على الزر */
  transition: transform 0.2s ease-in-out; /* إضافة حركة بسيطة */
}

.custom-button:hover {
  transform: scale(1.05); /* تكبير الزر قليلاً عند التمرير عليه */
}


.avatar-lg {
  width: 100%; /* تعيين العرض ليكون 100% */
  max-width: 300px; /* أقصى عرض للصورة */
  height: auto; /* تعيين الارتفاع ليكون تلقائياً */
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 15px;
}

.custom-avatar {
    width: 200px;
    height: 200px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.dropbtn {
    color: warning;
    padding: 2px;
    font-size: 14px;
    border: none;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
    padding-left: 15%;
     padding-right: 15%;
}

.dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px 
        0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: warning;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1
}

.dropdown:hover .dropdown-content {
    display: block;
}
.dropdown:hover .dropbtn {
    background-color: rgba(131, 149, 145,0.2);
}

.dropdown-content .Advertisement-item {
    margin-bottom: 15px; /* يمكنك تعديل القيمة كما تريد */
    cursor: pointer;
}
.meal-item_packege {
    margin-bottom: 20px; /* يمكنك تعديل القيمة كما تريد */
}

.divcorsor{
  cursor: pointer;
}


.delete-text{
  color: #ff6e41;
  
  line-height: 1.8;
  font-family: "Times New Roman", Times, serif;
  font-style: oblique;
  font-weight: bold;
  font-size: 22px;
}


.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* لضبط المسافة العلوية إذا لزم الأمر */
}

.button-group .bbutton {
  margin: 0 10px; /* لضبط المسافة الأفقية بين الأزرار */
}


.styled-date-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.styled-date-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-group .bbutton {
  margin: 0 10px;
}


#hiddenElement {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
}

.vertical-line {
    width: 2px; /* عرض الخط */
    height: 40%; /* طول الخط يساوي ارتفاع الشاشة بالكامل */
    background-color: rgb(158, 158, 158); /* لون الخط */
    position: absolute;
    left: 50%; /* موضع الخط في منتصف الصفحة */
    transform: translateX(-50%);
}


.textAddon .textAddonprice{
  color: #057014;
  display: inline-block;
  max-width: none;
}

.textAddonprice{
  color: #037514;
}

.textWithout{
color: #630d1d;
}

.table {
  width: auto;
}
th, td {
    white-space: nowrap;
}
.tdAddon{
  padding-right: 20%;
}

.tdAddon2{
  padding-left: 20%;
}

@keyframes shakeAndGrow {
      0%, 100% {
        transform: translateX(0) scale(1);
      }
      10%, 30%, 50%, 70%, 90% {
        transform: translateX(-10px) scale(1.1);
      }
      20%, 40%, 60%, 80% {
        transform: translateX(10px) scale(0.9);
      }
    }

    
    .meal-container {
  display: flex;
  flex-wrap: wrap;
  max-height: 140px; /* ارتفاع ثابت للعنصر الذي يحتوي على الجدول */
  min-height:100px;
  overflow-y: auto; /* تمكين التمرير العمودي */
  border-radius: 10px;
  margin: 1%;
}

.meal-item {
  flex: 1 0 45%; /* يجعل العنصر يأخذ 45% من العرض مع هامش */
  margin: 5px;
  box-sizing: border-box; /* ليشمل الهامش ضمن العرض */
}
</style>