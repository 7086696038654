<template>
    <div class="container-fluid py-4">

      <div class="row">
      <div class="col-12">
        <div class="card1 my-4">
          <div class="card-header p-0 position-relative mt-n5 mx-3 z-index-2">
            <div class="custom-bg shadow-success border-radius-lg pt-4 pb-3 ">
              <div class="ms-auto">  
                <div class="d-flex flex-row justify-content-between">
                  <h6 class="text-warning text-capitalize ps-3 me-12" v-if="!get_mealDataToEditBool()">Add New Meal:</h6>
                  <h6 class="text-warning text-capitalize ps-3 me-12" v-else>Edit Meal:</h6>
                  <div class="deleteDiv" v-if="get_mealDataToEditBool()">
                    <button class=" toRTL badge badge-lg bg-gradient-warning" @click="deleteMealFunction(meal)">
                      Delete
                      <i class="pi pi-minus-circle"></i>
                    </button>
                  </div>

                  <div class="addDiv">
                    <button class=" toRTL badge badge-lg bg-gradient-warning" @click="uploadMeal">
                      SAVE
                      <i class="pi pi-plus-circle"></i>
                    </button>
                  </div>
                </div>                
              </div>
            </div>

          </div>
          <div class="card-body px-0 pb-2">
            <div class="card-body">
              <div class="button-group">
                <div class="col-lg-6">
                    <div class='custom-bg shadow-success border-radius-lg pt-4 pb-3'> 
                      <b-form-group label="Meal Name:" class="bformgroup inputpadding">
                        <b-form-input v-model="newMeal.name" class="bforminput" required></b-form-input>
                      </b-form-group>
                      <b-form-group label="Meal Description:" class="bformgroup inputpadding">
                        <b-form-textarea v-model="newMeal.description" rows="5" required class="bformtextarea"></b-form-textarea>
                      </b-form-group>
                      <b-form-group label="Meal Price:" class="bformgroup inputpadding">
                        <b-form-input v-model="newMeal.price" class="bforminput" required type="number"></b-form-input>
                      </b-form-group>
                    </div>
                  
                </div>
                <div class="col-lg-6">
                    <div class=' categoryContinerPadding custom-bg shadow-success border-radius-lg pt-1 pb-1'>
                      <b-form-group label="Meal Category:" class="">
                    <div class='container-side-by-side'> 
                      <div class="dropdown categoryimage">
                        <button class="dropbtn avatar avatar-xl rounded-circle me-2">
                          <img :src="newMeal.categoryImage" width="50" height="50"
                                    class="avatar avatar-xl rounded-circle me-2" alt="Category" /> 
                        </button>
                        <div class="dropdown-content">
                            <div v-for="category in categoryList" :key = category.id class="category-item" @click="getCategoryID(category)">
                                <img :src="category.URL" class="avatar avatar-sm me-3 border-radius-lg imgpadding2"  
                                width="30" height="30"> {{category.name}}</div>
                        </div>
                      </div>
                      <div class="mb-0 text-lg text-warning categoryname">{{ newMeal.categoryName }}</div>
                    </div>
                  </b-form-group>

                  <div class='container-side-by-side'>
                      <img v-if="get_mealDataToEditBool()" :src="newMeal.image || defaultImage" alt="Meal Image" class=" border-radius-lg custom-avatar" style="padding-left:1%">
                      <div>
                        <b-form-group label="Meal Image:"></b-form-group>
                        <div class="imageInput">    
                          <picture-input 
                            ref="pictureInput"
                            width="400" 
                            height="250" 
                            margin="10" 
                            accept="image/jpeg,image/png" 
                            size="10" 
                            button-class="btn"
                            :custom-strings="{
                              upload: '<h1>Bummer!</h1>',
                              drag: 'Drag an Image or Click to select Image from Files'
                            }"
                            @change="onMealFileChange">
                          </picture-input>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>


      <div class="row">
  
  
  <div class="centered" v-if="showMealDelete">
        <div class="my-auto mx-auto col-5" >
        <div class="row">
          <div class="mx-auto">
            <div class="card z-index-0 fadeIn3 fadeInBottom mx-auto">
              <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div
                  class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
                >
                  <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                    Delete Meal
                  </h4>
                </div>
              </div>
              <div class="card-body">
                <div>
                  <p class="delete-text">Are you sure you want to delete {{ deleteMeal.name }} meal?</p>
                </div>
              <div class="button-group">
                <b-button @click="deleteMealAPI(deleteMeal.id)" variant="success" class="bbutton">ADD Category</b-button>
                <b-button @click="showMealDelete = false" variant="danger" class="bbutton">Cancel</b-button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="centered" v-if="showOfferToMeal">
        <div class="my-auto mx-auto col-5" >
        <div class="row">
          <div class="mx-auto">
            <div class="card z-index-0 fadeIn3 fadeInBottom mx-auto">
              <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div
                  class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
                >
                  <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                    Add Offer To Meal
                  </h4>
                </div>
              </div>
              <div class="card-body">
                <b-form-group label="Meal Date" label-for="meal-date">
                  <input type="Date" id="OfferToMeal" name="bdaymonth" v-model="OfferToMeal.date" :min="minDate" class="styled-date-input" placeholder="Choose a date">
                </b-form-group>
                <b-form-group label="Meal Price After Discount" class="bformgroup">
                    <b-form-input v-model="OfferToMeal.price" class="bforminput" required type="number"></b-form-input>
                </b-form-group>
              <div class="button-group">
                <b-button @click="OfferToMealAPI(OfferToMeal.id)" variant="success" class="bbutton">ADD Offer</b-button>
                <b-button @click="showOfferToMeal = false" variant="danger" class="bbutton">Cancel</b-button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  
  
  
  
  
    <div class="centered" v-if="showOfferEdit">
        <div class="my-auto mx-auto col-5" >
        <div class="row">
          <div class="mx-auto">
            <div class="card z-index-0 fadeIn3 fadeInBottom mx-auto">
              <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div
                  class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
                >
                  <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                   Edit Offer To Meal
                  </h4>
                </div>
              </div>
              <div class="card-body">
                
              <div class="button-group">
                <b-button @click="EditOfferAPI(OfferToEdit.id)" variant="success" class="bbutton">Edit Offer</b-button>
                <b-button @click="showDeleteOfferFunction(OfferToEdit.id)" variant="danger">Delete</b-button>
                <b-button @click="showOfferEdit = false" variant="danger" class="bbutton">Cancel</b-button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  
  
  
  
    <div class="centered" v-if="showOfferDelete">
        <div class="my-auto mx-auto col-5" >
        <div class="row">
          <div class="mx-auto">
            <div class="card z-index-0 fadeIn3 fadeInBottom mx-auto">
              <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div
                  class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
                >
                  <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                    Delete Package Offer
                  </h4>
                </div>
              </div>
              <div class="card-body">
                <div>
                  <p class="delete-text">Are you sure you want to Delete Offer?</p>
                </div>  
              <div class="button-group">
                <b-button @click="deleteOfferAPI(OfferToDelete.id)" variant="success" class="bbutton">Delete</b-button>
                <b-button @click="showOfferDelete = false" variant="danger" class="bbutton">Cancel</b-button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  
  
  
  
  
  
    <div class="centered" v-if="showAddAddonsToMeal">
        <div class="my-auto mx-auto col-5" >
        <div class="row">
          <div class="mx-auto">
            <div class="card z-index-0 fadeIn3 fadeInBottom mx-auto">
              <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div
                  class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
                >
                  <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                    Add Addon To Meal
                  </h4>
                </div>
              </div>
              <div class="card-body">
                <b-form-group label="ِAddon" class="bformgroup">
                  <b-form-input v-model="AddAddons.addon" class="bforminput" required></b-form-input>
                </b-form-group>
                <b-form-group label="Price" class="bformgroup">
                    <b-form-input v-model="AddAddons.price" class="bforminput" required type="number"></b-form-input>
                </b-form-group>
              <div class="button-group">
                <b-button @click="AddAddonsToMealAPI(AddAddons.id)" variant="success" class="bbutton">ADD Addon</b-button>
                <b-button @click="showAddAddonsToMeal = false" variant="danger" class="bbutton">Cancel</b-button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  
  
  
  
  
    <div class="centered" v-if="showAddwithoutToMeal">
        <div class="my-auto mx-auto col-5" >
        <div class="row">
          <div class="mx-auto">
            <div class="card z-index-0 fadeIn3 fadeInBottom mx-auto">
              <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div
                  class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
                >
                  <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                    Add without To Meal
                  </h4>
                </div>
              </div>
              <div class="card-body">
                <b-form-group label="Without Name" class="bformgroup">
                  <b-form-input v-model="Addwithout.without_name" class="bforminput" required></b-form-input>
                </b-form-group>
              <div class="button-group">
                <b-button @click="AddwithoutToMealAPI(Addwithout.id)" variant="success" class="bbutton">ADD without</b-button>
                <b-button @click="showAddwithoutToMeal = false" variant="danger" class="bbutton">Cancel</b-button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  
    <div class="centered" v-if="showAddonswithoutsToMeal">
        <div class="my-auto mx-auto col-5" >
        <div class="row">
          <div class="mx-auto">
            <div class="card z-index-0 fadeIn3 fadeInBottom mx-auto">
              <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div
                  class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
                >
                  <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                    {{mealDetailsToShowInAddonsAndWithout.name}}
                  </h4>
                </div>
              </div>
              <div class="card-body">
                <div class="align-middle text-center text-sm">
                  <p class="text-xs font-weight-bold mb-0">{{ mealDetailsToShowInAddonsAndWithout.categoryName }}</p>
                </div>
  
                  <div class="d-flex px-2 py-1 divcorsor">
                    <div>
                      <img :src=" mealDetailsToShowInAddonsAndWithout.URL" class="avatar avatar-sm me-3 border-radius-lg"
                        alt="Meal" />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{mealDetailsToShowInAddonsAndWithout.name}}</h6>
                      <p class="text-xs text-secondary mb-0 description-text">
                        {{mealDetailsToShowInAddonsAndWithout.description}}
                      </p>
                    </div>
                  </div>
                  <div class="button-group">
                  <div class="card-body px-0 pb-2">
                        <div class="table-responsive p-0">
                          <table class="align-items-center mb-0">
                            <thead>
                              <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Addon
                                </th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 tdAddon2">
                                  Price
                                </th>
                                </tr>
                            </thead>
                            <tbody v-if="getAddonsBool">
                            <template v-if="Addonscount > 0" >
                              <tr v-for="Addon in AddonsList" :key = Addon.id>
                                <td class="tdAddon">
                                  <div class="d-flex px-2 py-1 divcorsor">
                                    <div class= "justify-content-center">
                                      <h6 class="mb-0 text-sm textAddon">{{Addon.addon}}</h6>
                                    </div>
                                  </div>
                                </td>
                                <td class="tdAddon2">
                                  <p class="text-xs font-weight-bold textAddonprice">{{ Addon.addon_price }}</p>
                                </td>
                              </tr>
                            </template>
                            </tbody>
                          <div v-else>
                            <div class="loader2"></div>
                          </div>
                          </table>
                        </div>
                      </div>
  
  
                      <div class="vertical-line"></div>
  
                      <div class="card-body px-0 pb-2">
                        <div class="table-responsive p-0">
                          <table class=" align-items-center mb-0">
                            <thead>
                              <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Without
                                </th>
                                </tr>
                            </thead>
                            <tbody v-if="getWithoutBool">
                            <template v-if="Withoutcount > 0" >
                              <tr v-for="Without in WithoutList" :key = Without.id>
                                <td>
                                  <div class="d-flex px-1 py-1 divcorsor">
                                    <div class="d-flex flex-column justify-content-center">
                                      <h6 class="mb-0 text-sm textWithout">{{Without.without_name}}</h6>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </template>
                            </tbody>
                          <div v-else>
                            <div class="loader2"></div>
                          </div>
                          </table>
                        </div>
                      </div>
  
                    </div>
              <div class="button-group">
                <b-button @click="showAddAddonsFunction()" variant="success">ADD Addon</b-button>
                <b-button @click="showAddwithoutFunction()" variant="success">ADD Without</b-button>
                <b-button @click="showAddonswithoutsToMeal = false" variant="danger" class="bbutton">Cancel</b-button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  

<div class="centered" v-if="showMealDelete">
      <div class="my-auto mx-auto col-5" >
      <div class="row">
        <div class="mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom mx-auto">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Delete Meal
                </h4>
              </div>
            </div>
            <div class="card-body">
              <div>
                <p class="delete-text">Are you sure you want to delete {{ deleteMeal.name }} meal?</p>
              </div>
            <div class="button-group">
              <b-button @click="deleteMealAPI(deleteMeal.id)" variant="success" class="bbutton">ADD Category</b-button>
              <b-button @click="showMealDelete = false" variant="danger" class="bbutton">Cancel</b-button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
      </div>
    </div>
  </template>
  
  <script setup>

  import 'vue-select/dist/vue-select.css';
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  import { BaseURL, userLog ,imageURL, mealDataToEdit, get_mealDataToEditBool } from './DATA/login';
  import { ref } from 'vue';
  import PictureInput from 'vue-picture-input'
  
  
  //import { computed } from "vue";
  //import { useStore } from "vuex";
  
  //const store = useStore();
  
  
  const axios = require('axios');
  
  //const nomeal = "@/assets/img/resturent/addResturant.jpg";
  
  let Addonscount  = ref(0); 
  let Withoutcount  = ref(0); 
  
  
  
  let categorycount  = ref(0); 
  let categoryList = ref([]);
  let categoryList_ = [];
  let getCategoryBool = ref(false);
  
  let AddonsList = [];
  let AddonsList_ = [];
  let WithoutList = [];
  let WithoutList_ = [];
  
  let getAddonsBool = ref(false);
  let getWithoutBool = ref(false);
  
  let showMealModal=  ref(false);
  let showMealDelete=  ref(false);
  let showOfferToMeal=  ref(false);
  let showOfferDelete=  ref(false);
  let showOfferEdit=  ref(false);
  let showAddAddonsToMeal=  ref(false);
  let showAddwithoutToMeal=  ref(false);
  let showAddonswithoutsToMeal=  ref(false);
  
  let minDate = new Date().toISOString().split('T')[0]
  
  
  
  
  
  
  let newMeal= ref({
    id : null,
    name: '',
    description: '',
    price: null,
    image: null,
    category: '',
    categoryImage: null,
    categoryName:'',
    categoryid:'',
  });
  
  

  
  let deleteMeal= ref({
    id: '',
    name: '',
  });
  
  
  
  
  let OfferToMeal= ref({
    id: '',
    price: null,
    date: null
  });
  
  let OfferToDelete= ref({
    id: '',
    price: null,
    date: null
  });
  
  
  let OfferToEdit= ref({
    id: '',
    price: null,
    date: null
  });
  
  
  
  let AddAddons= ref({
    id: '',
    price: null,
    addon: '',
  });
  
  let Addwithout= ref({
    id: '',
    without_name: '',
  });
  
  
  let mealDetailsToShowInAddonsAndWithout= ref({
    id: '',
    name: '', 
    price: null, 
    description:'', 
    end_date: '', 
    price_after_discount: null, 
    categoryName: '', 
    URL: null,
  });
    
  
  





  const showAddwithoutFunction = async () => {
    showAddonswithoutsToMeal.value = false;
    showAddwithoutToMeal.value = true;
    Addwithout.value.id = mealDetailsToShowInAddonsAndWithout.value.id;
    getWithouts(Addwithout.value.id);
  };
  
  const AddwithoutToMealAPI = async (mealID) => {
    const formData = new FormData();
        formData.append('without_name', Addwithout.value.without_name);
    const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: BaseURL+'addNewWitoutToMeal/'+mealID,
          headers: { 
            'Authorization': 'Bearer '+userLog.token, 
          },
          data: formData
        };
  
        try {
          const response = await axios.request(config);
          console.log(JSON.stringify(response.data));
          toast(response.data.message, {
                theme: "colored",
                type: "success",
                position: "top-left",
                autoClose: 3000,
              });
              showAddwithoutToMeal.value = false;
              showAddonswithoutsToMeal.value = true;
        } catch (error) {
          console.error(error);
          toast(error.message, {
                theme: "colored",
                type: "error",
                position: "top-left",
                autoClose: 3000,
              });
        }
  };
  
  
  const showAddAddonsFunction = async () => {
    showAddonswithoutsToMeal.value = false;
    showAddAddonsToMeal.value = true;
    AddAddons.value.id = mealDetailsToShowInAddonsAndWithout.value.id;
    getAddons(AddAddons.value.id);
  };
  
  
  const AddAddonsToMealAPI = async (mealID) => {
    const formData = new FormData();
        formData.append('addon', AddAddons.value.addon);
        formData.append('addon_price', AddAddons.value.price);
    const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: BaseURL+'addNewAddonsToMeal/'+mealID,
          headers: { 
            'Authorization': 'Bearer '+userLog.token, 
          },
          data: formData
        };
  
        try {
          const response = await axios.request(config);
          console.log(JSON.stringify(response.data));
          toast(response.data.message, {
                theme: "colored",
                type: "success",
                position: "top-left",
                autoClose: 3000,
              });
              showAddonswithoutsToMeal.value = true;
              showAddAddonsToMeal.value = false;
        } catch (error) {
          console.error(error);
          toast(error.message, {
                theme: "colored",
                type: "error",
                position: "top-left",
                autoClose: 3000,
              });
        }
  };
  
  
  
  const OfferToMealAPI = async (mealID) => {
    const formData = new FormData();
        formData.append('end_date', OfferToMeal.value.date);
        formData.append('price_after_discount', OfferToMeal.value.price);
    const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: BaseURL+'addOfferToMeal/'+mealID,
          headers: { 
            'Authorization': 'Bearer '+userLog.token, 
          },
          data: formData
        };
  
        try {
          const response = await axios.request(config);
          console.log(JSON.stringify(response.data));
          toast(response.data.Result, {
                theme: "colored",
                type: "success",
                position: "top-left",
                autoClose: 3000,
              });
              showOfferToMeal.value = false;
        } catch (error) {
          console.error(error);
          toast(error.message, {
                theme: "colored",
                type: "error",
                position: "top-left",
                autoClose: 3000,
              });
        }
  };
  
  
  
  
  const EditOfferAPI = async (mealID) => {
    const formData = new FormData();
        formData.append('end_date', OfferToEdit.value.date);
        formData.append('price_after_discount', OfferToEdit.value.price);
    const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: BaseURL+'editOffer/'+mealID,
          headers: { 
            'Authorization': 'Bearer '+userLog.token, 
          },
          data: formData
        };
  
        try {
          const response = await axios.request(config);
          console.log(JSON.stringify(response.data));
          toast(response.data.Result, {
                theme: "colored",
                type: "success",
                position: "top-left",
                autoClose: 3000,
              });
              showOfferToMeal.value = false;
        } catch (error) {
          console.error(error);
          toast(error.message, {
                theme: "colored",
                type: "error",
                position: "top-left",
                autoClose: 3000,
              });
        }
  };
  
  
  
  
  const showDeleteOfferFunction = async (mealid) => {
    showOfferDelete.value = true;
    OfferToDelete.value.id = mealid
  };
  
  const deleteOfferAPI = async (mealID) => {
    const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: BaseURL+'deleteOffer/'+mealID,
          headers: { 
            'Authorization': 'Bearer '+userLog.token, 
          },
        };
  
        try {
          const response = await axios.request(config);
          console.log(JSON.stringify(response.data));
          toast(response.data, {
                theme: "colored",
                type: "success",
                position: "top-left",
                autoClose: 3000,
              });
              showOfferToMeal.value = false;
        } catch (error) {
          console.error(error);
          toast(error.message, {
                theme: "colored",
                type: "error",
                position: "top-left",
                autoClose: 3000,
              });
        }
  };
  
  
  const deleteMealFunction = (meal) =>{
    showMealDelete.value = true;
    deleteMeal.value.id = meal.id;
    deleteMeal.value.name = meal.name;
  };
  
  const deleteMealAPI = async (mealID) =>{
    const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: BaseURL+'deleteMeal/'+mealID,
          headers: { 
            'Authorization': 'Bearer '+userLog.token, 
          },
        };
  
        try {
          const response = await axios.request(config);
          console.log(JSON.stringify(response.data));
          toast(response.data.Result, {
                theme: "colored",
                type: "success",
                position: "top-left",
                autoClose: 3000,
              });
              showMealDelete.value = false;
        } catch (error) {
          console.error(error);
          toast(error.messege, {
                theme: "colored",
                type: "error",
                position: "top-left",
                autoClose: 3000,
              });
        }
  };
  
  
  
  
  
  
  
  
  const getCategoryID = (category) =>{
    newMeal.value.category = category.id;
    newMeal.value.categoryImage = category.URL;
    newMeal.value.categoryName = category.name;
      };
  
  
  const onMealFileChange = (event) => {
    const file = event.target.files[0];
    newMeal.value.image = file;
  };
  
  const uploadMeal = async () => {
    const data = new FormData();
    data.append('name', newMeal.value.name);
    data.append('description', newMeal.value.description);
    data.append('price', newMeal.value.price);
    data.append('photo', newMeal.value.image);
    data.append('category_id', newMeal.value.category);  // يمكنك تغيير هذا إلى قيمة الفئة المناسبة
  

  let url = ref('addaddMeal');
  if(get_mealDataToEditBool())
    url.value = 'updateMeal/' + newMeal.value.id;
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseURL + url.value,
      headers: { 
        'Authorization': 'Bearer '+userLog.token, 
      },
      data: data
    };
  
    try {
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      toast(response.data.message, {
            theme: "colored",
            type: "success",
            position: "top-left",
            autoClose: 3000,
          });
          showMealModal.value = false;
    } catch (error) {
      console.error(error);
      toast(error.messege, {
            theme: "colored",
            type: "error",
            position: "top-left",
            autoClose: 3000,
          });
    }
  };
  
  
  
  
    const getAddons = async(id) => {
      AddonsList = [];
      getAddonsBool.value = false;
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: BaseURL+'showDetailAddionMeal/'+id,
      };
      await axios.request(config)
      .then((response) => {
        AddonsList_ = response.data;
        for (let i = 0; i< AddonsList_.length; i++)
      {
        let one_Addon = { 
          id: AddonsList_[i].id,
          addon: AddonsList_[i].addon, 
          addon_price: AddonsList_[i].addon_price, 
          meal_id: AddonsList_[i].meal_id, 
        };
  
        AddonsList.push(one_Addon);
      }
      Addonscount.value = AddonsList_.length;
      getAddonsBool.value = true;
      })
      .catch((error) => {
        toast("Error in get Addons..", {
            theme: "colored",
            type: "error",
            position: "top-left",
            autoClose: 3000,
          });
        console.log(error);
      });
    };
  



    const getWithouts = async(id) => {
      WithoutList = [];
      getWithoutBool.value = false;
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: BaseURL+'showDetailWithoutMeal/'+id,
      };
      await axios.request(config)
      .then((response) => {
        WithoutList_ = response.data;
        for (let i = 0; i< WithoutList_.length; i++)
      {
        let one_Without = { 
          id: WithoutList_[i].id,
          without_name: WithoutList_[i].without_name, 
          meal_id: WithoutList_[i].meal_id, 
        };
  
        WithoutList.push(one_Without);
      }
      Withoutcount.value = WithoutList_.length;
      getWithoutBool.value = true;
      })
      .catch((error) => {
        toast("Error in get Withouts..", {
            theme: "colored",
            type: "error",
            position: "top-left",
            autoClose: 3000,
          });
        console.log(error);
      });
    };  
   
  
  


  const getCategory = async() => {
    getCategoryBool.value = false;
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL+'getCategoryMyOwner',
      headers: { 
        'Authorization': 'Bearer '+userLog.token
      }
    };
    await axios.request(config)
    .then((response) => {
      categoryList_ = response.data;
      for (let i = 0; i< categoryList_.length; i++)
    {
      let one_category = { 
        id: categoryList_[i].id,
        name: categoryList_[i].name, 
        photo: categoryList_[i].photo, 
        URL: imageURL + categoryList_[i].photo,
      };
      categoryList.value.push(one_category);
    }
    categorycount.value = categoryList_.length;
    getCategoryBool.value = true;
    console.log(categoryList);
    })
    .catch((error) => {
      toast("Error in get Category..", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });

      console.log(error);
    });
  };


  getCategory();
  
  
  if(get_mealDataToEditBool()){
    console.log("mealDataToEdit",mealDataToEdit);
    newMeal.value.id = mealDataToEdit.id;
    newMeal.value.name = mealDataToEdit.name;
    newMeal.value.description = mealDataToEdit.description;
    newMeal.value.price = mealDataToEdit.price;
    newMeal.value.image = mealDataToEdit.image;
    newMeal.value.categoryid = mealDataToEdit.category;
    newMeal.value.categoryName = mealDataToEdit.categoryName;
    newMeal.value.categoryImage = mealDataToEdit.categoryImage;
    console.log("newMeal",newMeal.value);

  };
  </script>
  
  
  <style>
  
  .price{
    color:#ff8c00;
  }
  .contenerLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 500px;
  }
  
  
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid rgb(251, 99, 64);
    border-bottom: 4px solid rgb(251, 99, 64);
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  .loader2 {
    top: 50%;
    left: 50%;
    z-index: 1;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid rgb(251, 99, 64);
    border-bottom: 4px solid rgb(251, 99, 64);
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  
  .container {
    display: flex;
    justify-content: flex-end; /* يضع العنصر في أقصى اليسار */
    align-items: center;
    padding-left: 20rem; /* إضافة مسافة صغيرة على اليسار لضمان عدم تلاصقه مع الحافة */
  }
  
  .add-button {
    justify-content: flex-start; /* يضع العنصر في أقصى اليسار */
  
    background-color: transparent; /* يمكنك تغيير اللون كما تحب */
    border: none; /* إزالة الحدود الافتراضية للزر */
    color: white; /* لون الأيقونة */
    font-size: 2rem; /* حجم الأيقونة */
    cursor: pointer; /* تغيير المؤشر عند التحويم */
    display: flex;
    padding-left: 4rem; 
  }
  
  .add-to_package {
    justify-content: flex-start; 
    border: none; 
    color: rgb(135, 175, 186); 
    font-size: 1.2rem; 
    cursor: pointer; 
    display: flex;
    padding-left: 1rem; 
  }
  .add-to_package2 {
    justify-content: flex-start; 
    border: none; 
    color: #ff8f07; 
    font-size: 1.5rem; 
    cursor: pointer; 
    display: flex;
    padding-left: 1rem; 
  }
  
  .add-button i {
    font-size: 2rem;
  }
  .file-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 8px;
      padding: 16px;
      background-color: #f9f9f9;
  }
  
  
  .imageInput {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 10%;
      padding: 5px;
      border-radius: 15px;
      box-shadow: 0 4px 8px rgba(250, 130, 1, 0.2);
      background: #f9f9f9;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .imageInput:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 16px rgba(251, 125, 0, 0.3);
  }
  
  .picture-input {
      border-radius: 15px;
      overflow: hidden;
      transition: all 0.3s ease;
  }
  
  .picture-input:hover {
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .btn {
      background-color: warning;
      color: warning;
      border-radius: 8px;
      padding: 10px 20px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  }
  
  .btn:hover {
      background-color: #f8ddc4;
  }
  
  
  .bbutton {
    margin: 10px;
    padding: 10px 20px;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .bbutton:hover {
    transform: scale(1.05);
  }
  
  .bformgroup {
    margin-bottom: 20px;
  }
  
  .bforminput, .b-form-file,.bformtextarea {
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #ffae00;
    transition: border-color 0.3s ease;
  }
  
  .bforminput:focus, .bformfile:focus,.bformtextarea:focus {
    border-color: #d79403;
    box-shadow: 0 0 5px #ffae00;
  }
  
  
  
  .container-side-by-side { 
      display: flex; 
      flex-direction: row; 
      flex-wrap: wrap; 
  } 
  
  .card1 {
    width: 100%;
    max-width: 90%; /* زيادة الحد الأقصى للعرض */
    margin: 0 auto; /* لضمان أن البطاقة تكون في الوسط */
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: #f9f9f9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card1:hover {
    transform: scale(1.01);
  }
  
  
  .cardadd1 {
    width: 40%;
    max-width: 40%; /* زيادة الحد الأقصى للعرض */
    margin: 0 auto; /* لضمان أن البطاقة تكون في الوسط */
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: #f9f9f9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .cardadd1:hover {
    transform: scale(1.01);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .description-text {
    max-width: 300px; /* يمكنك تعديل هذا العرض حسب الحاجة */
    word-wrap: break-word; /* لتقسيم الكلمات الكبيرة */
    overflow-wrap: break-word; /* لدعم المتصفحات الحديثة */
    white-space: normal; /* للسماح بالالتفاف على الأسطر المتعددة */
  }
  
  .modal-header {
    background-color: rgb(5, 250, 238);
    color: white;
  }
  
  .custom-button {
    border: none; /* إزالة الحواف */
    cursor: pointer; /* تغيير المؤشر عند التمرير على الزر */
    transition: transform 0.2s ease-in-out; /* إضافة حركة بسيطة */
  }
  
  .custom-button:hover {
    transform: scale(1.05); /* تكبير الزر قليلاً عند التمرير عليه */
  }
  
  
  .avatar-lg {
    width: 100%; /* تعيين العرض ليكون 100% */
    max-width: 300px; /* أقصى عرض للصورة */
    height: auto; /* تعيين الارتفاع ليكون تلقائياً */
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 15px;
  }
  
  .custom-avatar {
      width: 100px;
      height: 100px;
  }
  
  .center {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .dropbtn {
      background-color: #ff8c00;
      color: rgb(248, 219, 184);
      padding: 2px;
      font-size: 16px;
      border: none;
      cursor: pointer;
  }
  
  .dropdown {
      position: relative;
      display: inline-block;
  }
  
  .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 
          0px rgba(0, 0, 0, 0.2);
      z-index: 1;
  }
  
  .dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
  }
  
  .dropdown-content a:hover {
      background-color: #f1f1f1
  }
  
  .dropdown:hover .dropdown-content {
      display: block;
  }
  .dropdown:hover .dropbtn {
      background-color: rgba(131, 149, 145,0.2);
  }
  
  .dropdown-content .category-item {
      margin-bottom: 15px; /* يمكنك تعديل القيمة كما تريد */
      cursor: pointer;
  }
  .meal-item_packege {
      margin-bottom: 20px; /* يمكنك تعديل القيمة كما تريد */
  }
  
  .divcorsor{
    cursor: pointer;
  }
  
  
  .delete-text{
    color: crimson;
    
    line-height: 1.8;
    font-family: "Times New Roman", Times, serif;
    font-style: oblique;
    font-weight: bold;
    font-size: 22px;
  }
  
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; /* لضبط المسافة العلوية إذا لزم الأمر */
  }
  
  .button-group .bbutton {
    margin: 0 10px; /* لضبط المسافة الأفقية بين الأزرار */
  }
  
  
  .styled-date-input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .styled-date-input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .button-group .bbutton {
    margin: 0 10px;
  }
  
  
  #hiddenElement {
      margin-top: 10px;
      padding: 10px;
      border: 1px solid #ccc;
      background-color: #f9f9f9;
  }
  
  .vertical-line {
      width: 2px; /* عرض الخط */
      height: 40%; /* طول الخط يساوي ارتفاع الشاشة بالكامل */
      background-color: rgb(158, 158, 158); /* لون الخط */
      position: absolute;
      left: 50%; /* موضع الخط في منتصف الصفحة */
      transform: translateX(-50%);
  }
  
  
  .textAddon .textAddonprice{
    color: #ff8c00;
    display: inline-block;
    max-width: none;
  }
  
  .textAddonprice{
    color: #ff8c00;
  }
  
  .textWithout{
  color: #630d1d;
  }
  
  .table {
    width: auto;
  }
  th, td {
      white-space: nowrap;
  }
  .tdAddon{
    padding-right: 20%;
  }
  
  .tdAddon2{
    padding-left: 20%;
  }
  
  @keyframes shakeAndGrow {
        0%, 100% {
          transform: translateX(0) scale(1);
        }
        10%, 30%, 50%, 70%, 90% {
          transform: translateX(-10px) scale(1.1);
        }
        20%, 40%, 60%, 80% {
          transform: translateX(10px) scale(0.9);
        }
      }
  
      .animated-icon {
        display: inline-block;
        animation: shakeAndGrow 500s infinite;
      }
  
      .custom-checkbox {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
  
      .custom-checkbox:checked {
        background-color: #ff8f07;
        color: #ff8f07;
      }
  
      .custom-checkbox:checked:after {
        width: 5px;
        height: 10px;
        background-color: #ff8f07;
      }
  
      .selected-row {
        background-color: #ffcf95;
        border-radius: 20px;
      }
      .centered {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999; /* قيمة عالية لضمان عرضه فوق العناصر الأخرى */
      background-color: rgba(83, 78, 78, 0.4); /* خلفية شفافة */
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* ظل لإضافة تأثير تشويه الواجهة */
  }
  
  .toRTL{
    float: right;
    
    border: none; /* إزالة الحواف */
    cursor: pointer; /* تغيير المؤشر عند التمرير على الزر */
    transition: transform 0.2s ease-in-out; /* إضافة حركة بسيطة */
  }
  
  
  .toRTL:hover {
    transform: scale(1.05); /* تكبير الزر قليلاً عند التمرير عليه */
  }
  
  .custom-bg {
    background-color: #faebbc;
    border: 2px solid #ff8f07;
    color: #FFC107;
  }
  .addDiv{
    padding-top: 0%;
    padding-right: 5%;
    padding-bottom: 0%;
    padding-left: 0%;
  
  }
  .deketeDiv{
    padding-top: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-left: 0%;
  
  }
  .packageDiv{
    padding-top: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-left: 30%;
  }
  
  
  
  .meal-table {
    width: 100%; /* عرض الجدول ليأخذ العرض الكامل للحاوية */
    border-collapse: collapse; /* دمج الحدود لتفادي تكرارها */
  }
  
  
  .meal-table thead {
    background-color: #ff8c00; /* لون خلفية رؤوس الأعمدة */
  }

  .categoryimage{
    padding-top: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-left: 10%;
  }

  .categoryname{
    padding-top: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-left: 20%;
  }

  .inputpadding{
    padding-top: 0%;
    padding-right: 5%;
    padding-bottom: 0%;
    padding-left: 5%;
  }

  .categoryContinerPadding{
    padding-top: 0%;
    padding-right: 0%;
    padding-bottom: 5%;
    padding-left: 0%;
  }
  .imagepadding{
    padding-top: 0%;
    padding-right: 50%;
    padding-bottom: 0%;
    padding-left: 50%;
  }

  .imgpadding2{
    padding-top: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-left: 5%;
  }
  </style>