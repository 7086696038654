<template>
    <div class="container-fluid py-4">

      <div class="row">
      <div class="col-12">
        <div class="card1 my-4">
          <div class="card-header p-0 position-relative mt-n5 mx-3 z-index-2">
            <div class="custom-bg shadow-success border-radius-lg pt-4 pb-3 ">
              <div class="ms-auto">  
                <div class="d-flex flex-row justify-content-between">
                  <h6 class="text-warning text-capitalize ps-3 me-12" v-if="!get_packageOfferToEditBool()">Add New packageOffer:</h6>
                  <h6 class="text-warning text-capitalize ps-3 me-12" v-else>Edit packageOffer:</h6>
                  <div class="addDiv">
                    <button class=" toRTL badge badge-lg bg-gradient-warning" @click="uploadpackageOffers">
                      SAVE
                      <i class="pi pi-plus-circle"></i>
                    </button>
                  </div>
                </div>                
              </div>
            </div>

          </div>
          <div v-if="get_mealsID().value.length < 1" class="massege-container">
            <div class="text-warning massege-text test-center" style="padding-top: 10%; padding-left: 10%;">
              Go to Meals page Ang select the meals to Add to Package Offer, please..
            </div>
            <div style="padding-top: 10%; padding-right: 48%; padding-bottom: 10%;">
              <button class=" toRTL badge badge-lg bg-gradient-warning" @click="gotomeals">
                go to meals
                <i class="pi pi-arrow-circle-righ"></i>
              </button>
            </div>
          </div>
          <div v-else>
            <div class="card-body px-0 pb-2">
              <div class="card-body">
                <div class="button-group">
                  <div class="col-lg-6">
                      <div class='custom-bg shadow-success border-radius-lg pt-4 pb-3'> 
                        <b-form-group label="Name" class="bformgroup inputpadding">
                          <b-form-input v-model="newpackageOffers.name" class="bforminput" required></b-form-input>
                        </b-form-group>
                        <b-form-group label="Discount" class="bformgroup inputpadding">
                          <b-form-input v-model="newpackageOffers.discount" class="bforminput" required type="number"></b-form-input>
                        </b-form-group>
                        <b-form-group label="End Date" label-for="meal-date" class="inputpadding">
                          <input type="Date" id="OfferToMeal" name="bdaymonth" v-model="newpackageOffers.end_date" :min="minDate" class="styled-date-input" placeholder="Choose a date">
                        </b-form-group>
                      </div>
                    
                  </div>
                  <div class="col-lg-6">
                      <div class=' categoryContinerPadding custom-bg shadow-success border-radius-lg pt-1 pb-1'>
                    <div>
                    <b-form-group label="packageOffer Image">
                      <b-form-file @change="onpackageOffersFileChange" class="bformfile" required ></b-form-file>
                    </b-form-group>
                    <div class="imageInput">    
                      <picture-input 
                        ref="pictureInput"
                        width="300" 
                        height="300" 
                        margin="50" 
                        accept="image/jpeg,image/png" 
                        size="10" 
                        button-class="btn"
                        :custom-strings="{
                          upload: '<h1>Bummer!</h1>',
                          drag: 'Drag an Image or Click to select Image from Files'
                        }"
                        @change="onpackageOffersFileChange">
                      </picture-input>
                    </div>
                  </div>
                    
                    </div>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script setup>

  import 'vue-select/dist/vue-select.css';
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  import { BaseURL, userLog , get_packageOfferToEditBool,
    get_mealsID ,get_id_packegeOffer_toEdit,
    set_mealsID, set_selectedMeals, set_selectedMealsData,} from './DATA/login';
  import { ref } from 'vue';
  import PictureInput from 'vue-picture-input'
  
  
 
  import { useRouter } from 'vue-router';
    const router = useRouter()

  
  
  const axios = require('axios');
  
  
const gotomeals = () => {
  router.push({ path: '/tables' })
};

  let newpackageOffers= ref({
  name: '',
  meal_ids:[],
  discount: null,
  end_date: '',
  photo: null,
});

  let minDate = new Date().toISOString().split('T')[0]
  
  
  

  
  
  

  const onpackageOffersFileChange = (event) => {
  const file = event.target.files[0];
  newpackageOffers.value.photo = file;
};
let mealsID = ref([]);
  const uploadpackageOffers = async () => {
    
    mealsID.value = get_mealsID();
  const data = new FormData();
  data.append('name', newpackageOffers.value.name);
  for (var i = 0; i < get_mealsID().value.length; i++) 
    data.append(`meal_ids[${i}][mealId]`, mealsID.value.value[i].mealId);
  data.append('discount', newpackageOffers.value.discount);
  data.append('photo', newpackageOffers.value.photo);
  data.append('end_date', newpackageOffers.value.end_date);  ;
  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: BaseURL+'addOfferToMeals',
    headers: { 
      'Authorization': 'Bearer '+userLog.token, 
    },
    data: data
  };

  const config2 = {
    method: 'post',
    maxBodyLength: Infinity,
    url: BaseURL+'editOfferPackage/'+ get_id_packegeOffer_toEdit(),
    headers: { 
      'Authorization': 'Bearer '+userLog.token, 
    },
    data: data
  };
  try {
    let response = ref();
    if(!get_packageOfferToEditBool()){
       response.value = await axios.request(config);}
    else{
       response.value = await axios.request(config2);}
    toast(response.value.data, {
          theme: "colored",
          type: "success",
          position: "top-left",
          autoClose: 3000,
        });
        set_mealsID([]);
        set_selectedMeals([]);
        set_selectedMealsData([]);
        router.push({ path: '/table' })

  } catch (error) {
    toast("Error when Add data", {
          theme: "colored",
          type: "error",
          position: "top-left",
          autoClose: 3000,
        });
  }
};


  
  
  
  
  
  
  
  
  
  
  
  
  
  
  


  </script>
  
  
  <style>
  
  .price{
    color:#ff8c00;
  }
  .contenerLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 500px;
  }
  
  
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid rgb(251, 99, 64);
    border-bottom: 4px solid rgb(251, 99, 64);
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  .loader2 {
    top: 50%;
    left: 50%;
    z-index: 1;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid rgb(251, 99, 64);
    border-bottom: 4px solid rgb(251, 99, 64);
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  
  .container {
    display: flex;
    justify-content: flex-end; /* يضع العنصر في أقصى اليسار */
    align-items: center;
    padding-left: 20rem; /* إضافة مسافة صغيرة على اليسار لضمان عدم تلاصقه مع الحافة */
  }
  
  .add-button {
    justify-content: flex-start; /* يضع العنصر في أقصى اليسار */
  
    background-color: transparent; /* يمكنك تغيير اللون كما تحب */
    border: none; /* إزالة الحدود الافتراضية للزر */
    color: white; /* لون الأيقونة */
    font-size: 2rem; /* حجم الأيقونة */
    cursor: pointer; /* تغيير المؤشر عند التحويم */
    display: flex;
    padding-left: 4rem; 
  }
  
  .add-to_package {
    justify-content: flex-start; 
    border: none; 
    color: rgb(135, 175, 186); 
    font-size: 1.2rem; 
    cursor: pointer; 
    display: flex;
    padding-left: 1rem; 
  }
  .add-to_package2 {
    justify-content: flex-start; 
    border: none; 
    color: #ff8f07; 
    font-size: 1.5rem; 
    cursor: pointer; 
    display: flex;
    padding-left: 1rem; 
  }
  
  .add-button i {
    font-size: 2rem;
  }
  .file-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 8px;
      padding: 16px;
      background-color: #f9f9f9;
  }
  
  
  .imageInput {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 10%;
      padding: 5px;
      border-radius: 15px;
      box-shadow: 0 4px 8px rgba(250, 130, 1, 0.2);
      background: #f9f9f9;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .imageInput:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 16px rgba(251, 125, 0, 0.3);
  }
  
  .picture-input {
      border-radius: 15px;
      overflow: hidden;
      transition: all 0.3s ease;
  }
  
  .picture-input:hover {
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .btn {
      background-color: warning;
      color: warning;
      border-radius: 8px;
      padding: 10px 20px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  }
  
  .btn:hover {
      background-color: #f8ddc4;
  }
  
  
  .bbutton {
    margin: 10px;
    padding: 10px 20px;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .bbutton:hover {
    transform: scale(1.05);
  }
  
  .bformgroup {
    margin-bottom: 20px;
  }
  
  .bforminput, .b-form-file,.bformtextarea {
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #ffae00;
    transition: border-color 0.3s ease;
  }
  
  .bforminput:focus, .bformfile:focus,.bformtextarea:focus {
    border-color: #ffae00;
    box-shadow: 0 0 5px #ffae00;
  }
  
  
  
  .container-side-by-side { 
      display: flex; 
      flex-direction: row; 
      flex-wrap: wrap; 
  } 
  
  .card1 {
    width: 100%;
    max-width: 90%; /* زيادة الحد الأقصى للعرض */
    margin: 0 auto; /* لضمان أن البطاقة تكون في الوسط */
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: #f9f9f9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card1:hover {
    transform: scale(1.01);
  }
  
  
  .cardadd1 {
    width: 40%;
    max-width: 40%; /* زيادة الحد الأقصى للعرض */
    margin: 0 auto; /* لضمان أن البطاقة تكون في الوسط */
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: #f9f9f9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .cardadd1:hover {
    transform: scale(1.01);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .description-text {
    max-width: 300px; /* يمكنك تعديل هذا العرض حسب الحاجة */
    word-wrap: break-word; /* لتقسيم الكلمات الكبيرة */
    overflow-wrap: break-word; /* لدعم المتصفحات الحديثة */
    white-space: normal; /* للسماح بالالتفاف على الأسطر المتعددة */
  }
  
  .modal-header {
    background-color: rgb(5, 250, 238);
    color: white;
  }
  
  .custom-button {
    border: none; /* إزالة الحواف */
    cursor: pointer; /* تغيير المؤشر عند التمرير على الزر */
    transition: transform 0.2s ease-in-out; /* إضافة حركة بسيطة */
  }
  
  .custom-button:hover {
    transform: scale(1.05); /* تكبير الزر قليلاً عند التمرير عليه */
  }
  
  
  .avatar-lg {
    width: 100%; /* تعيين العرض ليكون 100% */
    max-width: 300px; /* أقصى عرض للصورة */
    height: auto; /* تعيين الارتفاع ليكون تلقائياً */
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 15px;
  }
  
  .custom-avatar {
      width: 100px;
      height: 100px;
  }
  
  .center {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  

  
  
  
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; /* لضبط المسافة العلوية إذا لزم الأمر */
  }
  
  .button-group .bbutton {
    margin: 0 10px; /* لضبط المسافة الأفقية بين الأزرار */
  }
  
  
  .styled-date-input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .styled-date-input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .button-group .bbutton {
    margin: 0 10px;
  }
  
  
  #hiddenElement {
      margin-top: 10px;
      padding: 10px;
      border: 1px solid #ccc;
      background-color: #f9f9f9;
  }
  
  .vertical-line {
      width: 2px; /* عرض الخط */
      height: 40%; /* طول الخط يساوي ارتفاع الشاشة بالكامل */
      background-color: rgb(158, 158, 158); /* لون الخط */
      position: absolute;
      left: 50%; /* موضع الخط في منتصف الصفحة */
      transform: translateX(-50%);
  }
  
  
  .textAddon .textAddonprice{
    color: #ff8c00;
    display: inline-block;
    max-width: none;
  }
  
  .textAddonprice{
    color: #ff8c00;
  }
  
  .textWithout{
  color: #630d1d;
  }
  
  .table {
    width: auto;
  }
  th, td {
      white-space: nowrap;
  }
  .tdAddon{
    padding-right: 20%;
  }
  
  .tdAddon2{
    padding-left: 20%;
  }
  
  @keyframes shakeAndGrow {
        0%, 100% {
          transform: translateX(0) scale(1);
        }
        10%, 30%, 50%, 70%, 90% {
          transform: translateX(-10px) scale(1.1);
        }
        20%, 40%, 60%, 80% {
          transform: translateX(10px) scale(0.9);
        }
      }
  
      .animated-icon {
        display: inline-block;
        animation: shakeAndGrow 500s infinite;
      }
  
      .custom-checkbox {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
  
      .custom-checkbox:checked {
        background-color: #ff8f07;
        color: #ff8f07;
      }
  
      .custom-checkbox:checked:after {
        width: 5px;
        height: 10px;
        background-color: #ff8f07;
      }
  
      .selected-row {
        background-color: #ffcf95;
        border-radius: 20px;
      }
      .centered {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999; /* قيمة عالية لضمان عرضه فوق العناصر الأخرى */
      background-color: rgba(83, 78, 78, 0.4); /* خلفية شفافة */
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* ظل لإضافة تأثير تشويه الواجهة */
  }
  
  .toRTL{
    float: right;
    
    border: none; /* إزالة الحواف */
    cursor: pointer; /* تغيير المؤشر عند التمرير على الزر */
    transition: transform 0.2s ease-in-out; /* إضافة حركة بسيطة */
  }
  
  
  .toRTL:hover {
    transform: scale(1.05); /* تكبير الزر قليلاً عند التمرير عليه */
  }
  
  .custom-bg {
    background-color: #faebbc;
    border: 2px solid #ff8f07;
    color: #FFC107;
  }
  .addDiv{
    padding-top: 0%;
    padding-right: 5%;
    padding-bottom: 0%;
    padding-left: 0%;
  
  }
  .deketeDiv{
    padding-top: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-left: 0%;
  
  }
  .packageDiv{
    padding-top: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-left: 30%;
  }
  
  
  
  .meal-table {
    width: 100%; /* عرض الجدول ليأخذ العرض الكامل للحاوية */
    border-collapse: collapse; /* دمج الحدود لتفادي تكرارها */
  }
  
  
  .meal-table thead {
    background-color: #ff8c00; /* لون خلفية رؤوس الأعمدة */
  }

  .categoryimage{
    padding-top: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-left: 10%;
  }

  .categoryname{
    padding-top: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-left: 20%;
  }

  .inputpadding{
    padding-top: 0%;
    padding-right: 5%;
    padding-bottom: 0%;
    padding-left: 5%;
  }

  .categoryContinerPadding{
    padding-top: 0%;
    padding-right: 0%;
    padding-bottom: 5%;
    padding-left: 0%;
  }
  .imagepadding{
    padding-top: 0%;
    padding-right: 50%;
    padding-bottom: 0%;
    padding-left: 50%;
  }

  .imgpadding2{
    padding-top: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-left: 5%;
  }

  
.massege-text{
  color: #ff6e41;
  font-size: larger;
  line-height: 1.8;
  font-family: "Times New Roman", Times, serif;
  font-style: oblique;
  font-weight: bold;
  font-size: 22px;
}

.massege-container {
  max-height: 360px; /* ارتفاع ثابت للعنصر الذي يحتوي على الجدول */
  min-height:200px;
  overflow-y: auto; /* تمكين التمرير العمودي */
  border: 2px solid #ff8c00; /* حدود الاختياري للعنصر */
  border-radius: 10px;
  margin: 1%;
}
  </style>