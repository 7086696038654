<template>
  <div class="py-4 container-fluid">
    <div class="image-container" v-if="isClosed == true">
      <img src="@/assets/img/resturent/resturentClosed2.png" class="centered-image" alt="You are close" />
    </div>
    <div class="container-side-by-side" v-else> 
      <div class="row col-12">
        <div class="col-12">
          <div class="card1 my-4">
            <div class="card-header p-0 position-relative mt-n5 mx-3 z-index-2">
            <div class="custom-bg shadow-success border-radius-lg pt-4 pb-3 ">
              <div class="ms-auto">  
                <div class="d-flex flex-row justify-content-between">
                <div  v-if="!getTheOrderbool" class="col-12">
                <div class="container-side-by-side ">
                  <h6 class="text-warning text-capitalize ps-3 me-5 col-4">Orders:</h6>
                  <div class="container-side-by-side addDiv1 col-7" v-if="!getTheOrderbool">
                    <div class="col-5">
                     <button class=" toRTL badge badge-lg bg-gradient-warning" @click="this.refresh()">
                        <i class="pi pi-sync"></i>
                      </button>
                    </div>
                      <button class=" toRTL badge badge-lg bg-gradient-warning" @click="boolShowUnderdelivaryReserve(boolShowUnderdelivary)" v-if="boolShowUnderdelivary == false">
                        Show underdelivary Orders
                        <i class="pi pi-eye"></i>
                      </button>
                      <button class=" toRTL badge badge-lg bg-gradient-warning" @click="boolShowUnderdelivaryReserve(boolShowUnderdelivary)" v-if="boolShowUnderdelivary == true">
                        Don't show underdelivary Orders
                        <i class="pi pi-eye-slash"></i>
                      </button>
                    </div>  
                  </div>

                    </div>
                  <div v-else class="col-12">
                    <div class="container-side-by-side">
                      <i class="col-1 divcorsor pi pi-angle-left" v-if="getTheOrderbool == true" @click="getTheOrderbool = false"
                      style="color: coral; font-size: 2rem; padding-left:2%"></i>
                      <div class="col-5">
                        <div>
                          <h5 class="text-warning ps-3 me-8" > {{ theOrder.user_name }}</h5>
                        </div>
                        <p style="padding-left: 5%;" class="text-xs text-info-emphasis mb-0 description-text"> {{ theOrder.user_phone }} | {{ theOrder.user_email }}</p>
                      </div>
                      <div class="col-3">
                        <div class="text-info-emphasis text-center">
                          <div>{{ theOrder.order_date }}</div>
                          <div>{{ theOrder.status }}</div>
                        </div>
                      </div>
                      <div class="col-3"> 
                        <div class="align-middle text-center text-lg" v-if="theOrder.status == 'Recipient'">
                          <button class="badge badge-sm bg-gradient-danger custom-button" @click="updateOrderStatusToBeingProcessedFunction(theOrder.id,theOrder.array_id)">
                            Accept
                          </button>
                        </div>
                        <div class="align-middle text-center text-lg" v-else-if="theOrder.status == 'Being_processed'">
                          <button class="badge badge-sm bg-gradient-warning custom-button" @click="updateOrderStatusToBIsReadyFunction(theOrder.id,theOrder.array_id)">
                            Is Ready
                          </button>
                        </div>
                        <div class="align-middle text-center text-lg" v-else-if="theOrder.status == 'Ready'">
                          <button class="badge badge-sm bg-gradient-info custom-button" @click="updateOrderStatusComfermToDelivaryFunction(theOrder.id,theOrder.array_id)">
                            Delivery confirm
                          </button>
                        </div>
                        <div class="align-middle text-center text-lg" v-else>
                          <img v-if="theOrder.status == 'Under_delivery'" :src="Under_delivery_image" class="center custom-button" style="width:10%;height:10%;"
                          alt="Under delivery" />
                        </div>
                      </div>
                    </div>
                  </div>

                      
                </div>
              </div>
            </div>
          </div>
            <div class="card-body px-0 pb-2">
              <div v-if="!getTheOrderbool">
                <div class="table-responsive p-0 table-container1" v-if="getOrderBool">
                
                  <table class="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          ID
                        </th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                          TIME
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          PRICE
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          status
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 custom-th">
                          Edit
                        </th>
                      
                        </tr>
                    </thead>
                    <tbody>
                    <template v-for="order in Ordercount" :key = order.id >
                      <tr class="divcorsor" @click="showTheOrder(this.ordersList[order-1].id, this.ordersList[order-1].array_id)" v-show="this.ordersList[order-1].show">
                        <td>
                          <div class="d-flex px-2 py-1 divcorsor">
                            <div class="d-flex flex-column justify-content-center">
                              <h6 class="mb-0 text-sm">{{this.ordersList[order-1].id}}</h6>
                            </div>
                          </div>
                        </td>
                        <td >
                          <p class="text-xs text-secondary mb-0 description-text">
                                {{this.ordersList[order-1].order_date}}
                              </p>
                        </td>
                        <td >
                          <p class="text-center text-xm font-weight-bold mb-0 price"> {{ this.ordersList[order-1].order_price }}</p>
                        </td>
                        <td class="align-middle text-center text-sm">
                          <p class="text-xs font-weight-bold mb-0">{{ this.ordersList[order-1].status }}</p>
                        </td>
                        <td class="align-middle text-center text-sm" v-if="this.ordersList[order-1].status == 'Recipient'">
                          <button class="badge badge-sm bg-gradient-danger custom-button" @click="updateOrderStatusToBeingProcessedFunction(this.ordersList[order-1].id,this.ordersList[order-1].array_id)">
                            Accept
                          </button>
                        </td>
                        <td class="align-middle text-center text-sm" v-else-if="this.ordersList[order-1].status == 'Being_processed'">
                          <button class="badge badge-sm bg-gradient-warning custom-button" @click="updateOrderStatusToBIsReadyFunction(this.ordersList[order-1].id,this.ordersList[order-1].array_id)">
                            Is Ready
                          </button>
                        </td>
                        
                        <td class="align-middle text-center text-sm" v-else-if="this.ordersList[order-1].status == 'Ready'">
                          <button class="badge badge-sm bg-gradient-info custom-button" @click="updateOrderStatusComfermToDelivaryFunction(this.ordersList[order-1].id,this.ordersList[order-1].array_id)">
                            Delivery confirm
                          </button>
                        </td>
                        <td class="align-middle text-center text-lg" v-else>
                          <img v-if="this.ordersList[order-1].status == 'Under_delivery'" src='@/assets/img/resturent/Under_delivery.png' class="center" style="width:30%;height:30%;"
                          alt="Under delivery" />
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  
                  </table>
                  
                </div>
                <div v-else>
                    <div class="loader2 center"></div>
                </div>
              </div>
              <div v-else>
                <div class="container-side-by-side"> 
                  <div class="text-center" :class="theOrder.offerscount < 1 ? theOrder.maxAddonCount > 0? theOrder.maxwithoutCount > 0?'col-12':'col-8':'col-6':'col-8'" v-if="theOrder.mealscount > 0">
                    <b-form-group label="MEALS">
                      <div>
                        <div v-for="meal in theOrder.meals" :key="meal.id" class="">
                          <div class="container-side-by-side">
                            <div class="col-4 container-side-by-side">
                              <p class="col-8 text-secondary text-xxs-left">Count</p>
                              <div class="quantity center font-weight-bolder text-danger col-2">{{ meal.quantity }}</div>
                              
                            </div>
                            <div class="col-5 container-side-by-side">
                              <img :src="meal.URL" class="text-left avatar avatar-lg me-3 border-radius-lg" width="30" height="30"> 
                              <div>
                                <h6 class="mb-0 text-md text-danger">{{ meal.name }}</h6>
                                <p class="text-sm text-secondary mb-0 description-text1">{{ meal.size }}</p>
                              </div>
                            </div>
                            <div>
                              <div class="button-group">
                                <div style="padding-left:30%;">
                                  <div class="card-body px-0 pb-2" v-if="meal.Addonscount > 0">
                                    <div class="table-responsive p-0">
                                      <table class="align-items-center mb-0">
                                        <thead>
                                          <tr>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                              Addon
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody v-if="meal.getAddonsBool">
                                          <tr v-for="Addon in meal.addons" :key = Addon.id>
                                            <td class="tdAddon">
                                              <div class="d-flex px-2 py-1 divcorsor">
                                                <div class= "justify-content-center">
                                                  <p class="mb-0 text-sm text-warning">{{Addon.addon}}</p>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>

                                        </tbody>
                                      <div v-else>
                                        <div class="loader2"></div>
                                      </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div style="padding-left:30%;">
                                  <div class="card-body px-0 pb-2" v-if="meal.Withoutcount > 0">
                                    <div class="table-responsive p-0">
                                      <table class=" align-items-center mb-0">
                                        <thead>
                                          <tr>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                              Without
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="meal.getWithoutBool">
                                          <tr v-for="Without in meal.withouts" :key = Without.id>
                                            <td>
                                              <div class="d-flex px-1 py-1 divcorsor">
                                                <div class="d-flex flex-column justify-content-center">
                                                  <p class="mb-0 text-sm text-danger">{{Without.without_name}}</p>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      <div v-else>
                                        <div class="loader2"></div>
                                      </div>
                                      </table>
                                    </div>
                                  </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="text-center" :class="theOrder.mealscount < 1 ?'col-12':'col-4'" v-if="theOrder.offerscount > 0">
                    <b-form-group label="OFFERS">
                      <div>
                        <div v-for="meal in theOrder.offers" :key="meal.id" class="category-item">
                          <div class="container-side-by-side">
                            <img :src="meal.URL" class="avatar avatar-lg me-3 border-radius-lg" width="30" height="30"> 
                            <div>
                              <h6 class="mb-0 text-md text-danger">{{ meal.name }}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-form-group>
                  </div>
                </div>
              


              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    

    <div class="col-9 container-side-by-side" >
      <div class="close-text">Closing the restaurant and temporarily stopping receiving orders</div>
        <div>
        <div class="align-middle text-center text-lg" v-if="isClosed == false" style="padding-left:20%">
          <button class="badge badge-sm bg-gradient-danger custom-button" @click="setClose('closed')">
            <i class="pi pi-lock" style="color: white; font-size: 1.5rem"></i> 
          </button>
        </div>
        <div class="align-middle text-center text-lg" v-else style="padding-left:20%">
          <button class="badge badge-sm bg-gradient-info custom-button" @click="setClose('now_open')">
            <i class="pi pi-lock-open" style="color: white; font-size: 1.5rem"></i>
          </button>
        </div>
        </div>
      </div>
  </div>
</template>
<script>


import { BaseURL, userLog ,imageURL } from './DATA/login';
import 'vue-select/dist/vue-select.css';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { ref  } from 'vue';
//import { FocusTrap } from 'focus-trap-vue';
import { useRouter } from 'vue-router';

export default {
  name: "dashboard-default",
  data() {
    return {
      router : useRouter(),
      movment_value:0.0,
      showModal: false,
      noOrder:imageURL+'dynamic Images/noOrder.jpg',
      Under_delivery_image:imageURL+'dynamic Images/Under_delivery.png',
      resturentClosed_image:imageURL+'dynamic Images/resturentClosed.png',
      orederDetails:[],
      orederDetail:{},
      ordersList: [],
      ordersList2: [
                    {
                        "id": 3,
                        "status": "Recipient",
                        "order_price": "204",
                        "delivery_cost": "400",
                        "order_date": "2024-06-18 19:28:06",
                        "paid": 0,
                        "user_id": 2,
                        "address_id": 3,
                        "delivery_received": 0,
                        "delivery_worker_id": "null",
                        "created_at": "2024-06-18T15:28:06.000000Z",
                        "updated_at": "2024-06-18T15:28:06.000000Z"
                    }
                ],
      ids: [],
      ids2:[1,2,3,4,5,6,7,8,9,10,11,12,13],
      resturantID :'',
      Ordercount: 0,
      getOrderBool: false,
      theOrder: {},
      getTheOrderbool: false,
      getTheOrderboolfinish: false,
      boolShowUnderdelivary:true,
      closeChoice:{
        icon:'pi pi-lock',
        color:'red',
      },
      isClosed: false,
      
    };
  },
  components: {
    
  },
  methods: {
    setClose( state ) {
        this.isClosed = !this.isClosed;
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        data.append('status', state);

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: BaseURL+'updateRasturent/2',
          headers: { 
            'Authorization': 'Bearer '+ userLog.token, 
          },
          data : data
        };
        let messege = "";
        if(this.isClosed)
          messege = "Closed";
        else
          messege = "Open";
        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          toast(response.data.Result+":  "+ messege, {
            autoClose: 2000,
          });
        })
        .catch((error) => {
          console.log(error);
        });

    },
    async boolShowUnderdelivaryReserve(){
      this.boolShowUnderdelivary = !this.boolShowUnderdelivary;
      for(let i = 0; i <this.ordersList.length; i++){
        if(this.ordersList[i].status =="Under_delivery"){
          if(!this.boolShowUnderdelivary)
            this.ordersList[i].show = false;
          else
            this.ordersList[i].show = true;
        }
      }

      
    },

    maxBetween(min, max){
      if(min>max)
        return min;
      else
        return max;
    },

    async showTheOrder(id,array_id){
      const axios = require('axios');
      let maxAddonCount = ref(0);
      let maxwithoutCount = ref(0);
      this.getTheOrderbool = true;
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: BaseURL+'getOrderDetails/'+ id,
          headers: { 
            'Authorization': 'Bearer '+ userLog.token
          }
        };
        await axios.request(config)
        .then(async (response) => {
          let meals = [];
          let offers = [];
          for (let i = 0; i < response.data.offers.length; i++) {
            let one_offer = {
              id: response.data.offers[i].id,
              name: response.data.offers[i].name,
              photo: response.data.offers[i].photo,
              URL: imageURL + response.data.offers[i].photo,
              price_after_discount:response.data.offers[i].price_after_discount,
              discount:response.data.offers[i].discount,
            };
            offers.push(one_offer);
          }
          for (let i = 0; i < response.data.meals.length; i++) {
            let addons = [];
            let withouts = [];
            for (let j = 0; j < response.data.meals[i].addons.length ; j++) {
                  let one_addon = {
                      id: response.data.meals[i].addons[j].addonId,
                      addon:response.data.meals[i].addons[j].addon_name,              
                    }
                    addons.push(one_addon);
              };
            for (let j = 0; j < response.data.meals[i].withouts.length ; j++) {
                let one_without = {
                    id: response.data.meals[i].withouts[j].id,
                    without_name:response.data.meals[i].withouts[j].without_name,              
                  }
                withouts.push(one_without); 
              };
            let one_meal = {
              id: response.data.meals[i].id,
              name: response.data.meals[i].name,
              photo: response.data.meals[i].photo,
              URL: imageURL + response.data.meals[i].photo,
              quantity:response.data.meals[i].quantity,
              size:response.data.meals[i].size,
              addons: addons,
              withouts: withouts,
              getAddonsBool: true,
              getWithoutBool: true,
              Addonscount: addons.length,
              Withoutcount: withouts.length,
            };
            meals.push(one_meal);
            maxAddonCount.value = this.maxBetween(addons.length, maxAddonCount.value);
            maxwithoutCount.value = this.maxBetween(withouts.length, maxwithoutCount.value);
          };
          let order_date = this.removeFirstAndLastFiveChars(response.data.order.order_date)
          this.theOrder = {
            id: response.data.order.id,
            status: response.data.order.status,
            order_price: response.data.order.order_price,
            order_date: order_date,
            user_id: response.data.user.id,
            user_name: response.data.user.name,
            user_phone: response.data.user.phone,
            user_email: response.data.user.email,
            meals: meals,
            offers: offers,
            mealscount: meals.length,
            offerscount: offers.length,
            array_id:array_id,
            maxAddonCount:maxAddonCount.value,
            maxwithoutCount: maxwithoutCount.value,
          };
          this.getTheOrderboolfinish = true;
        })
        .catch((error) => {
          toast("Errore on get Order Detail: "+error, {
          theme: "colored",
          type: "warning",
          position: "top-left",
          autoClose: 3000,
        });
        });
    },


    updateOrderStatusToBeingProcessedFunction(id, index){
      const axios = require('axios');

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL+'updateOrderStatusToBeingProcessed/'+id,
        headers: { 
          'Authorization': 'Bearer '+userLog.token
        }
      };

      axios.request(config)
      .then((response) => {
        toast(response.data, {
          theme: "colored",
          type: "success",
          position: "top-left",
          autoClose: 3000,
        });
        this.ordersList[index].status = "Being_processed"
        this.theOrder.status = "Being_processed"
      })
      .catch((error) => {
        toast("Errore: "+error, {
          theme: "colored",
          type: "warning",
          position: "top-left",
          autoClose: 3000,
        });
      });
    },


    updateOrderStatusToBIsReadyFunction(id, index){
      const axios = require('axios');

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL+'updateOrderStatusToReady/'+id,
        headers: { 
          'Authorization': 'Bearer '+userLog.token
        }
      };

      axios.request(config)
      .then((response) => {
        toast(response.data, {
          theme: "colored",
          type: "success",
          position: "top-left",
          autoClose: 3000,
        });
        this.ordersList[index].status = "Ready"
        this.theOrder.status = "Ready"
      })
      .catch((error) => {
        toast("Errore: "+error, {
          theme: "colored",
          type: "warning",
          position: "top-left",
          autoClose: 3000,
        });
      });
    },
    
    updateOrderStatusComfermToDelivaryFunction(id,index){
      const axios = require('axios');

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL+'confirmDeliveryOrderByResturent/'+id,
        headers: { 
          'Authorization': 'Bearer '+userLog.token
        }
      };

      axios.request(config)
      .then((response) => {
        toast(response.data.message, {
          theme: "colored",
          type: "success",
          position: "top-left",
          autoClose: 3000,
        });
        this.ordersList[index].status = "Under_delivery"
        this.theOrder.status = "Under_delivery"
        this.getTheOrderbool = false;
      })
      .catch((error) => {
        toast("Errore: "+error, {
          theme: "colored",
          type: "warning",
          position: "top-left",
          autoClose: 3000,
        });
      });
    },



    removeFirstAndLastFiveChars(input) {
      return input.substring(5, input.length - 3);
    },


    

    async getOrders(){
      
      const axios = require('axios');
      let config_toc = {
        method: 'get',
        maxBodyLength: Infinity,
        url: BaseURL+'GetIdResturentByToken',
        headers: { 
          'Authorization': 'Bearer '+userLog.token
        }
      };
      await axios.request(config_toc)
      .then((response) => {
        this.resturantID = response.data
      })
      .catch((error) => {
        console.log(error);
      });
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: BaseURL+'GetOrderForResturent/'+ this.resturantID,
        headers: { 
          'Authorization': 'Bearer '+ userLog.token
        }
      };
      await axios.request(config)
      .then((response) => { 
        for (var i = 0; i < response.data.length; i++) {
          let show = ref(true);
          if (response.data[i].delivery_received == 1) 
            show.value = false;
            let order_date = this.removeFirstAndLastFiveChars(response.data[i].order_date)
            let one_order = {
              id: response.data[i].id,
              status: response.data[i].status,
              order_price: response.data[i].order_price,
              order_date: order_date,
              user_id: response.data[i].user_id,
              show: show.value,
              array_id: i,
            };
            this.ordersList.push(one_order);
            this.ids.push(one_order.id);
          
          this.getOrderBool = true;
          this.Ordercount = this.ordersList.length
        }
      })
      .catch((error) => {
        toast("Errore: "+error, {
          theme: "colored",
          type: "danger",
          position: "top-left",
          autoClose: 3000,
        });
      });
    },

    async refresh(){
      console.log("refresh");
      this.ordersList = [];
      this.Ordercount = 0;
      await this.getOrders();
    },

  },
  async created() {
    await this.getOrders();
  },
  
};
</script>

<style>
.custom-th{
  width: 150px;
}

.quantity {
  border: none; 
  font-size: 1.5rem; 
  display: flex;
  padding-left: 5%; 
  padding-right: 5%;
  
}



  .bg-gradient-success {
    background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%);
  }

  .card-body {
    padding: 15px;
  }

  .button-group {
    display: flex;
    align-items: center;
  }

  .custom-button {
    margin: 5px;
  }

  .custom-button2 {
  align-items: center;
  justify-content: center;
  padding: 10; /* لإزالة الحشوة الافتراضية */
  margin: 10px;
}

  .category-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .container-side-by-side {
    display: flex;
    align-items: center;
    width: 100%;
  }

  

  .close-text{
  color: danger;
  line-height: 1.8;
  font-family: "Times New Roman", Times, serif;
  font-style: oblique;
  font-weight: bold;
  font-size: 22px;
}


.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%; /* يجعل الحاوية تملأ الشاشة بأكملها. يمكنك تعديلها حسب الحاجة. */
}

.centered-image {
  width: 50%;
  height: 40%;
}

.table-container1 {
  max-height: 400px; /* ارتفاع ثابت للعنصر الذي يحتوي على الجدول */
  overflow-y: auto; /* تمكين التمرير العمودي */
  border: 2px solid #ff8c00; /* حدود الاختياري للعنصر */
  border-radius: 5px; /* حدود دائرية للاختياري */
}

.addDiv1{
  padding-top: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  padding-left: 00%;

}

.description-text1 {
    max-width: 50px; /* يمكنك تعديل هذا العرض حسب الحاجة */
    overflow-wrap: break-word; /* لدعم المتصفحات الحديثة */
  }

.section-container1 {
  max-height: 360px; /* ارتفاع ثابت للعنصر الذي يحتوي على الجدول */
  min-height:100px;
  overflow-y: auto; /* تمكين التمرير العمودي */
  border: 2px solid #ff8c00; /* حدود الاختياري للعنصر */
  border-radius: 10px;
  margin: 1%;
}
</style>
